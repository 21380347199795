import React from "react";
import {unixToFormattedDateWithDay} from "../../utils/time";

const SickLeaveRequestCard = ({sickLeaveRequest}) => {
    return (
        <div className="card shadow-sm bg-light-subtle">
            <div className="card-body">
                <h5 className="card-title pb-2">Krankmeldung</h5>

                <div className={"row"}>
                    <div className="col-12 col-xl-3 d-flex mb-2">
                        <div
                            className="bg-warning rounded-circle d-flex justify-content-center align-items-center shadow-sm"
                            style={{minWidth: "3em", height: "3em"}}
                        >
                            <i className="bi bi-person text-secondary"
                               style={{fontSize: "1.75em"}}></i>
                        </div>
                        <div className="ms-3 mt-1 ">
                            <h6 className="mb-0">{sickLeaveRequest.user.name}</h6>
                            <span className="text-muted">{sickLeaveRequest.user.username}</span>
                        </div>
                    </div>

                    <div className="col-12 col-xl-3 mb-2">
                        <div className={"text-secondary"}>Von:</div>
                        <div className="fw-bold">{unixToFormattedDateWithDay(sickLeaveRequest.startDate)}</div>
                    </div>

                    <div className="col-12 col-xl-3 mb-2">
                        <div className={"text-secondary"}>Bis:</div>
                        <div className="fw-bold">{unixToFormattedDateWithDay(sickLeaveRequest.endDate)}</div>
                    </div>

                    <div className="col-12 col-xl-3">
                        <div className={"text-secondary"}>Zusätzliche Informationen:</div>
                        <div className={"fst-italic"}>{sickLeaveRequest.notes}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SickLeaveRequestCard;