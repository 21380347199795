import {useMemo} from "react";

const EmployeesStateAmountBar = ({overviewData}) => {
    const barPercentages = useMemo(() => {
        if (!overviewData) {
            return {
                working: "0%",
                holidays: "0%",
                sickleaves: "0%",
                notLogged: "0%",
            };
        }

        return {
            working: `${overviewData.working / overviewData.totalUsers * 100}%`,
            holidays: `${overviewData.holidays / overviewData.totalUsers * 100}%`,
            sickleaves: `${overviewData.sickleaves / overviewData.totalUsers * 100}%`,
            notLogged: `${overviewData.notLogged / overviewData.totalUsers * 100}%`,
        };
    }, [overviewData]);

    return (
        <div>
            <div className={"d-flex justify-content-around mb-3 gap-3"}>
                <div className={"d-flex flex-column flex-md-row text-center align-items-center gap-2"}>
                    <div style={{width: "1rem", height: "1rem"}} className={"bg-primary rounded-circle"}></div>
                    <div className={"text-muted"}>bei der Arbeit</div>
                </div>
                <div className={"d-flex flex-column flex-md-row text-center align-items-center gap-2"}>
                    <div style={{width: "1rem", height: "1rem"}} className={"bg-success rounded-circle"}></div>
                    <div className={"text-muted"}>im Urlaub</div>
                </div>
                <div className={"d-flex flex-column flex-md-row text-center align-items-center gap-2"}>
                    <div style={{width: "1rem", height: "1rem"}} className={"bg-warning rounded-circle"}></div>
                    <div className={"text-muted"}>im Krankenstand</div>
                </div>
                <div className={"d-flex flex-column flex-md-row text-center align-items-center gap-2"}>
                    <div style={{width: "1rem", height: "1rem"}} className={"bg-secondary rounded-circle"}></div>
                    <div className={"text-muted"}>nicht gemeldet</div>
                </div>
            </div>
            <div className="progress-stacked" style={{height: "4rem"}}>
                <div className="progress"
                     style={{width: barPercentages.working, height: "4rem"}}
                >
                    <div className="progress-bar bg-primary flex-row align-items-center gap-2 fs-4">
                        <i className="bi bi-person-fill-gear text-light-emphasis"></i>
                        <span className={"fw-bold"}>{overviewData.working}</span>
                    </div>
                </div>
                <div className="progress"
                     style={{width: barPercentages.holidays, height: "4rem"}}
                >
                    <div className="progress-bar bg-success flex-row align-items-center gap-2 fs-4">
                        <i className="bi bi-sun-fill text-light-emphasis"></i>
                        <span className={"fw-bold"}>{overviewData.holidays}</span>
                    </div>
                </div>
                <div className="progress"
                     style={{width: barPercentages.sickleaves, height: "4rem"}}
                >
                    <div className="progress-bar bg-warning flex-row align-items-center gap-2 fs-4">
                        <i className="bi bi-file-earmark-medical-fill text-light-emphasis"></i>
                        <span className={"fw-bold"}>{overviewData.sickleaves}</span>
                    </div>
                </div>
                <div className="progress"
                     style={{width: barPercentages.notLogged, height: "4rem"}}
                >
                    <div className="progress-bar bg-secondary flex-row align-items-center gap-2 fs-4">
                        <i className="bi bi-question-octagon-fill text-light-emphasis"></i>
                        <span className={"fw-bold"}>{overviewData.notLogged}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmployeesStateAmountBar;