import {getFormattedTime} from "../../utils/time";

const WorkdayTimeHistory = ({workday}) => {
    return (
        <div className={"d-flex gap-2"} style={{fontSize: "0.8em"}}>
            <div className={"text-end w-100"}>
                {workday.startTime &&
                    <div className={"my-4"}>
                        <span className={"bg-light-50 text-white rounded-start-5 rounded-end-2 p-2 px-3 shadow"}>
                            Arbeitstag gestartet
                        </span>
                    </div>
                }

                {workday.breaks?.map((breakTime, index) => {
                    return (
                        <div className={"py-1"} key={index}>
                            <div className={"my-4"}>
                                <span
                                    className={"bg-light-50 text-white rounded-start-5 rounded-end-2 p-2 px-3 shadow"}>
                                    Pause gestartet
                                </span>
                            </div>
                            {breakTime.endTime &&
                                <div className={"my-4"}>
                                    <span
                                        className={"bg-light-50 text-white rounded-start-5 rounded-end-2 p-2 px-3 shadow"}>
                                        Pause beendet
                                    </span>
                                </div>
                            }
                        </div>
                    );
                })}

                {workday.endTime &&
                    <div className={"my-4"}>
                        <span className={"bg-light-50 text-white rounded-start-5 rounded-end-2 p-2 px-3 shadow"}>
                            Arbeitstag beendet
                        </span>
                    </div>
                }
            </div>

            <div className="vr"></div>

            <div className={"w-100"}>
                {workday.startTime &&
                    <div className={"my-4"}>
                        <span className={"bg-success text-dark rounded-start-2 rounded-end-5 p-2 px-3 shadow"}>
                            {getFormattedTime(workday.startTime)}
                        </span>
                    </div>
                }

                {workday.breaks?.map((breakTime, index) => {
                    return (
                        <div className={"py-1"} key={index}>
                            <div className={"my-4"}>
                                <span className={"bg-warning text-dark rounded-start-2 rounded-end-5 p-2 px-3 shadow"}>
                                    {getFormattedTime(breakTime.startTime)}
                                </span>
                            </div>
                            {breakTime.endTime &&
                                <div className={"my-4"}>
                                    <span
                                        className={"bg-warning-subtle text-dark rounded-start-2 rounded-end-5 p-2 px-3 shadow"}>
                                        {getFormattedTime(breakTime.endTime)}
                                    </span>
                                </div>
                            }
                        </div>
                    );
                })}

                {workday.endTime &&
                    <div className={"my-4"}>
                        <span className={"bg-success text-dark rounded-start-2 rounded-end-5 p-2 px-3 shadow"}>
                            {getFormattedTime(workday.endTime)}
                        </span>
                    </div>
                }
            </div>
        </div>
    );
};

export default WorkdayTimeHistory;