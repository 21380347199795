import React, {useEffect, useMemo} from "react";
import SickLeaveRequestCard from "../../../components/employeeRequest/SickLeaveRequestCard";
import HolidayRequestCard from "../../../components/employeeRequest/HolidayRequestCard";
import {Image} from "react-bootstrap";
import useAPI from "../../../hooks/useAPI";
import TimeCompRequestCard from "../../../components/employeeRequest/TimeCompRequestCard";

const Requests = () => {
    const {data: holidayRequestsData} = useAPI('admin/holiday', {autoCall: true});
    const {callAPI: callHolidayAccept} = useAPI('admin/holiday/accept', {method: "PATCH"});
    const {callAPI: callHolidayDecline} = useAPI('admin/holiday/decline', {method: "PATCH"});
    const {data: timeCompRequestsData} = useAPI('admin/timecomp', {autoCall: true});
    const {callAPI: callTimeCompAccept} = useAPI('admin/timecomp/accept', {method: "PATCH"});
    const {callAPI: callTimeCompDecline} = useAPI('admin/timecomp/decline', {method: "PATCH"});
    const {data: sickleaveRequestsData} = useAPI('admin/sickleave', {autoCall: true});

    const [sickLeaveRequests, setSickLeaveRequests] = React.useState(null);
    const [holidayRequests, setHolidayRequests] = React.useState(null);
    const [timeCompRequests, setTimeCompRequests] = React.useState(null);

    useEffect(() => setSickLeaveRequests(sickleaveRequestsData ?? []), [sickleaveRequestsData]);
    useEffect(() => setHolidayRequests(holidayRequestsData ?? []), [holidayRequestsData]);
    useEffect(() => setTimeCompRequests(timeCompRequestsData ?? []), [timeCompRequestsData]);

    // sorting by startDate
    const sortedSickLeaveRequests = useMemo(() => {
        if (sickLeaveRequests === null) return;
        return [...sickLeaveRequests].sort((a, b) => a.startDate - b.startDate);
    }, [sickLeaveRequests]);

    // sorting by state and startDate
    const sortedHolidayRequests = useMemo(() => {
        if (holidayRequests === null) return;
        return [...holidayRequests].sort((a, b) => {
            if (a.state !== b.state) {
                return a.state - b.state;
            }
            return a.startDate - b.startDate;
        });
    }, [holidayRequests]);

    // sorting by state and startDate
    const sortedTimeCompRequests = useMemo(() => {
        if (timeCompRequests === null) return;
        return [...timeCompRequests].sort((a, b) => {
            if (a.state !== b.state) {
                return a.state - b.state;
            }
            return a.startDate - b.startDate;
        });
    }, [timeCompRequests]);

    const handleHolidayRequestApprove = (id) => {
        callHolidayAccept({endpointAddition: `/${id}`}).then(data => {
            setHolidayRequests(holidayRequests.map((holidayRequest) => {
                if (holidayRequest.id === id) {
                    holidayRequest.state = 1;
                }
                return holidayRequest;
            }));
        });
    };

    const handleHolidayRequestDecline = (id) => {
        callHolidayDecline({endpointAddition: `/${id}`}).then(data => {
            setHolidayRequests(holidayRequests.map((holidayRequest) => {
                if (holidayRequest.id === id) {
                    holidayRequest.state = 2;
                }
                return holidayRequest;
            }));
        });
    };

    const handletimeCompRequestApprove = (id) => {
        callTimeCompAccept({endpointAddition: `/${id}`}).then(data => {
            setTimeCompRequests(timeCompRequests.map((timeCompRequest) => {
                if (timeCompRequest.id === id) {
                    timeCompRequest.state = 1;
                }
                return timeCompRequest;
            }));
        });
    }

    const handleTimeCompRequestDecline = (id) => {
        callTimeCompDecline({endpointAddition: `/${id}`}).then(data => {
            setTimeCompRequests(timeCompRequests.map((timeCompRequest) => {
                if (timeCompRequest.id === id) {
                    timeCompRequest.state = 2;
                }
                return timeCompRequest;
            }));
        });
    }

    return (
        <>
            <div className={"bg-primary-subtle p-3 d-flex align-items-center shadow-sm mb-4"}>
                <h1 className={"container text-dark-emphasis fs-1 d-flex align-items-center gap-3"}>
                    <div
                        className={"bg-primary text-white d-flex justify-content-center align-items-center rounded-circle shadow-sm"}
                        style={{minWidth: "3.5rem", height: "3.5rem", fontSize: "2rem"}}
                    >
                        <i className="bi bi-card-checklist"></i>
                    </div>
                    <div>Urlaubsanträge & Krankmeldungen</div>
                </h1>
            </div>

            {sickLeaveRequests === null || holidayRequests === null ?
                <>
                    LOADING
                </>
                :
                <div className={"m-2 m-sm-3 m-md-5"}>
                    <div className={"d-flex justify-content-center"}>
                        <div className="container">
                            <div className={"d-flex flex-column gap-3 mb-5"}>
                                <div className={"d-flex gap-3 align-items-center"}>
                                    <Image
                                        src={"/sick_leave.png"}
                                        className={"rounded-circle position-relative shadow-sm"}
                                        style={{top: -3}}
                                        width={60}
                                    />
                                    <h2>{sickLeaveRequests.length} aktive Krankmeldungen</h2>
                                </div>
                                {sortedSickLeaveRequests.map((sickLeaveRequest) =>
                                    <SickLeaveRequestCard
                                        key={sickLeaveRequest.id}
                                        sickLeaveRequest={sickLeaveRequest}
                                    />
                                )}
                            </div>

                            <div className={"d-flex flex-column gap-3 mb-5"}>
                                <div className={"d-flex gap-3 align-items-center"}>
                                    <Image
                                        src={"/request_free.png"}
                                        className={"rounded-circle position-relative shadow-sm"}
                                        style={{top: -3}}
                                        width={60}
                                    />
                                    <h2>{holidayRequests.length} aktive Urlaubsanträge</h2>
                                </div>
                                {sortedHolidayRequests.map((holidayRequest) =>
                                    <HolidayRequestCard
                                        key={holidayRequest.id}
                                        holidayRequest={holidayRequest}
                                        handleApprove={handleHolidayRequestApprove}
                                        handleDecline={handleHolidayRequestDecline}
                                    />
                                )}
                            </div>

                            <div className={"d-flex flex-column gap-3"}>
                                <div className={"d-flex gap-3 align-items-center"}>
                                    <Image
                                        src={"/time_comp.png"}
                                        className={"rounded-circle position-relative shadow-sm"}
                                        style={{top: -3}}
                                        width={60}
                                    />
                                    <h2>{timeCompRequests.length} aktive Zeitausgleich Anträge</h2>
                                </div>
                                {sortedTimeCompRequests.map((timeCompRequest) =>
                                    <TimeCompRequestCard
                                        key={timeCompRequest.id}
                                        timeCompRequest={timeCompRequest}
                                        handleApprove={handletimeCompRequestApprove}
                                        handleDecline={handleTimeCompRequestDecline}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Requests;