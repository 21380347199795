import {BrowserRouter, Outlet, Route, Routes, useNavigate} from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import ChangePassword from "./pages/createPassword/ChangePassword";
import Tracking from "./pages/tracking/Tracking";
import Navigation, {NAVIGATION_HEIGHT} from "./components/navigation/Navigation";
import Week from "./pages/week/Week";
import Personal from "./pages/personal/Personal";
import Workday from "./pages/workday/Workday";
import DayOverview from "./pages/admin/overview/DayOverview";
import AdminNavigation from "./components/adminNavigation/AdminNavigation";
import Requests from "./pages/admin/requests/Requests";
import Usermanagement from "./pages/admin/usermanagement/Usermanagement";
import Settings from "./pages/admin/settings/Settings";
import {AuthProvider, useAuth} from "./context/AuthContext";
import ProtectedRoute from "./components/navigation/ProtectedRoute";
import {ToastProvider} from "./context/ToastContext";
import PeriodOverview from "./pages/admin/overview/PeriodOverview";
import Overview from "./pages/admin/overview/Overview";

function WithNavigation() {
    return (
        <>
            <div
                id={"outlet"}
                className={`h-100 bg-primary bg-gradient`}
                style={{paddingBottom: NAVIGATION_HEIGHT}}
            >
                <Outlet/>
            </div>
            <Navigation/>
        </>
    );
}

function WithAdminNavigation() {
    const {isAdmin, userLoading} = useAuth();

    const navigate = useNavigate();

    if (userLoading) {
        return (
            <div className={"d-flex justify-content-center align-items-center h-100"}>
                <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        isAdmin ?
            <div className={"vh-100 d-flex"}>
                <AdminNavigation/>
                <div className={"w-100 overflow-y-auto pb-lg-0"} style={{paddingBottom: 60}}>
                    <Outlet/>
                </div>
            </div>
            :
            <div
                id={"outlet"}
                className={`h-100 bg-primary bg-gradient`}
            >
                <div className="py-4 mx-2 text-center text-light">
                    <h1 className={"display-1 my-3"}>Keine Berechtigung</h1>
                    <p className={"lead my-3"}>Du hast keine Berechtigung für den Administrator Bereich.</p>
                    <a className={"btn btn-primary btn-lg text-light my-3"} onClick={() => navigate("/")}>Zurück zum
                        Startbildschirm</a>
                </div>
                <Navigation/>
            </div>
    );
}

function App() {
    return (
        <AuthProvider>
            <ToastProvider>
                <div className="App vh-100 vw-100 overflow-hidden">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route>
                                <Route path="login" element={<Login/>}/>
                                <Route path="changepassword" element={<ChangePassword/>}/>
                            </Route>
                            <Route element={<WithNavigation/>}>
                                <Route element={<ProtectedRoute/>}>
                                    <Route path="tracking" element={<Tracking/>}/>
                                    <Route path="week" element={<Week/>}/>
                                    <Route path="personal" element={<Personal/>}/>
                                    <Route path="workday" element={<Workday/>}/>
                                </Route>
                            </Route>
                            <Route element={<WithAdminNavigation/>}>
                                <Route path="/admin/" element={<Overview/>}/>
                                <Route path="/admin/period" element={<PeriodOverview/>}/>
                                <Route path="/admin/requests" element={<Requests/>}/>
                                <Route path="/admin/usermanagement" element={<Usermanagement/>}/>
                                <Route path="/admin/settings" element={<Settings/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </div>
            </ToastProvider>
        </AuthProvider>
    );
}

export default App;
