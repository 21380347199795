import React, {useEffect, useState} from 'react';
import ModalWithChildren from "../../components/modal/ModalWithChildren";
import {Alert, Button, Form} from 'react-bootstrap';
import {unixToInputDate} from "../../utils/time";

const SickLeaveModal = ({
                            show,
                            setShow,
                            onConfirm,
                            onDelete,
                            editSickleaveRequest,
                            setEditSickleaveRequest
                        }) => {
    const [sickLeaveRequest, setSickLeaveRequest] = useState({});
    const [valid, setValid] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    useEffect(() => {
        // clear the form when the modal is closed
        if (!show) {
            setSickLeaveRequest({});
            setEditSickleaveRequest({});
        }

        // check for edit
        if (show && editSickleaveRequest) {
            setSickLeaveRequest(editSickleaveRequest);
        }
    }, [show]);

    const validateFields = () => {
        const {startDate, endDate} = sickLeaveRequest;
        const today = new Date().toISOString().split('T')[0];
        if (startDate && endDate) {
            if (unixToInputDate(startDate) < today) {
                setValid(false);
                setValidationMessage('Startdatum darf nicht in der Vergangenheit liegen.');
            } else if (endDate < startDate) {
                setValid(false);
                setValidationMessage('Enddatum darf nicht vor dem Startdatum liegen.');
            } else {
                setValid(true);
                setValidationMessage('');
            }
        }
    };

    useEffect(() => {
        validateFields();
    }, [sickLeaveRequest]);

    const handleChange = (e) => {
        const id = e.target.id;
        let value = e.target.value;

        // convert date values to unix timestamps
        if (id === 'startDate') {
            value = new Date(value);
            value = value.setHours(0, 0, 0, 0);
        }
        if (id === 'endDate') {
            value = new Date(value)
            value = value.setHours(23, 59, 59, 999);
        }

        setSickLeaveRequest(prev => ({...prev, [id]: value}));
    };

    const handleSubmit = () => {
        if (valid) {
            onConfirm(sickLeaveRequest);
        }
    };

    return (
        <ModalWithChildren
            show={show}
            setShow={setShow}
            title={editSickleaveRequest?.id ? "Krankmeldung bearbeiten" : "Krankmeldung einreichen"}
            confirmButtonText={editSickleaveRequest?.id ? "Krankmeldung bearbeiten" : "Krankmeldung abschicken"}
            confirmButtonVariant={"warning"}
            paramsValid={valid}
            onConfirm={handleSubmit}
        >
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="startDate">
                    <Form.Label>
                        <span>Startdatum</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="date"
                        value={unixToInputDate(sickLeaveRequest.startDate)}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group className="my-3" controlId="endDate">
                    <Form.Label>
                        <span>Enddatum</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="date"
                        value={unixToInputDate(sickLeaveRequest.endDate)}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group className={"my-3"} controlId="notes">
                    <Form.Label>Zusätzliche Informationen</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={sickLeaveRequest.notes ?? ""}
                        onChange={handleChange}
                    />
                </Form.Group>
                {validationMessage && <Alert variant="danger">{validationMessage}</Alert>}
            </Form>

            {editSickleaveRequest?.id && (
                <div className={"mt-4 float-start"}>
                    <Button
                        variant={"danger"}
                        size={"sm"}
                        onClick={onDelete}
                    >
                        Krankmeldung löschen
                    </Button>
                </div>
            )}
        </ModalWithChildren>
    );
};

export default SickLeaveModal;