import {useEffect, useState} from "react";
import useAPI from "../../hooks/useAPI";
import {useToast} from "../../context/ToastContext";
import {Link, useNavigate} from "react-router-dom";

const ChangePassword = () => {
    const renderToast = useToast();
    const navigate = useNavigate();
    const {callAPI} = useAPI('auth/change-password', {method: 'POST'});

    const [passwords, setPasswords] = useState({
        passwordOld: '',
        password: '',
        passwordCheck: ''
    });
    const [validationMessages, setValidationMessages] = useState(null);
    const [blurred, setBlurred] = useState({passwordOld: false, password: false, passwordCheck: false});
    const [showPasswords, setShowPasswords] = useState({passwordOld: false, password: false, passwordCheck: false});

    const handleChange = (e) => {
        const {id, value} = e.target;
        setPasswords(prevPasswords => ({
            ...prevPasswords,
            [id]: value
        }));
    };

    const toggleShowPassword = (field) => {
        setShowPasswords(prevShowPasswords => ({
            ...prevShowPasswords,
            [field]: !prevShowPasswords[field]
        }));
    };

    useEffect(() => {
        if (validationMessages?.passwordOld && passwords.passwordOld) {
            setValidationMessages(prev => ({...prev, passwordOld: null}));
        }

        if (passwords.password.length < 8) {
            if (!validationMessages?.password) {
                setValidationMessages(prev => ({
                    ...prev,
                    password: 'Das Passwort muss mindestens 8 Zeichen lang sein.'
                }));
            }
        } else if (validationMessages?.password) {
            setValidationMessages(prev => ({...prev, password: null}));
        }

        if (passwords.password !== passwords.passwordCheck) {
            if (!validationMessages?.passwordCheck) {
                setValidationMessages(prev => ({
                    ...prev,
                    passwordCheck: 'Die Passwörter stimmen nicht überein.'
                }));
            }
        } else if (validationMessages?.passwordCheck) {
            setValidationMessages(prev => ({...prev, passwordCheck: null}));
        }
    }, [passwords]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            validationMessages?.passwordOld
            || validationMessages?.password
            || validationMessages?.passwordCheck
        ) return;

        callAPI({
            body: {oldPassword: passwords.passwordOld, password: passwords.password}
        }).then(data => {
            navigate('/');
        }).catch(err => {
            renderToast(`Ein Fehler ist aufgetreten. ${err}`, 'bg-danger');
            setValidationMessages({passwordOld: 'Überprüfe deine Eingabe'});
        });
    };

    return (
        <>
            <div className={"bg-light lead p-2"}>
                <Link to={"/personal"}>
                    <i className="bi bi-chevron-left me-1"></i>
                    <span>Zurück</span>
                </Link>
            </div>
            <div className="h-100 d-flex align-items-center justify-content-center bg-light p-2">
                <div className="container bg-white rounded-5 p-3 py-5 p-md-5" style={{maxWidth: 900}}>
                    <h4 className="text-center mb-5 bg-primary-50 text-white rounded-2 p-3 shadow">
                        <span className={"fw-bold"}>Strenn</span> – Zeiterfassungssystem
                    </h4>
                    <div className="row">
                        <div className="col-md-6">
                            <h3>Passwort ändern</h3>
                            <p className="text-muted">
                                Hier kannst du ein neues Passwort setzen
                            </p>
                        </div>
                        <div className="col-md-6">
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label htmlFor="passwordOld" className="form-label">Altes Passwort</label>
                                    <div className="input-group">
                                        <input
                                            type={showPasswords.passwordOld ? "text" : "password"}
                                            className={`form-control ${blurred.passwordOld && validationMessages?.passwordOld ? 'is-invalid' : ''}`}
                                            id="passwordOld"
                                            value={passwords.passwordOld}
                                            onChange={handleChange}
                                            onBlur={() => setBlurred(prev => ({...prev, passwordOld: true}))}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => toggleShowPassword('passwordOld')}
                                        >
                                            <i className={showPasswords.passwordOld ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                                        </button>
                                        {validationMessages?.passwordOld &&
                                            <div className="invalid-feedback">
                                                {validationMessages?.passwordOld}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="password" className="form-label">Neues Passwort</label>
                                    <div className="input-group">
                                        <input
                                            type={showPasswords.password ? "text" : "password"}
                                            className={`form-control ${blurred.password && validationMessages?.password ? 'is-invalid' : ''}`}
                                            id="password"
                                            value={passwords.password}
                                            onChange={handleChange}
                                            onBlur={() => setBlurred(prev => ({...prev, password: true}))}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => toggleShowPassword('password')}
                                        >
                                            <i className={showPasswords.password ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                                        </button>
                                        {validationMessages?.password &&
                                            <div className="invalid-feedback">
                                                {validationMessages?.password}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="passwordCheck" className="form-label">Neues Passwort
                                        wiederholen</label>
                                    <div className="input-group">
                                        <input
                                            type={showPasswords.passwordCheck ? "text" : "password"}
                                            className={`form-control ${blurred.passwordCheck && validationMessages?.passwordCheck ? 'is-invalid' : ''}`}
                                            id="passwordCheck"
                                            value={passwords.passwordCheck}
                                            onChange={handleChange}
                                            onBlur={() => setBlurred(prev => ({...prev, passwordCheck: true}))}
                                        />
                                        <button
                                            type="button"
                                            className="btn btn-outline-secondary"
                                            onClick={() => toggleShowPassword('passwordCheck')}
                                        >
                                            <i className={showPasswords.passwordCheck ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                                        </button>
                                        {validationMessages?.passwordCheck &&
                                            <div className="invalid-feedback">
                                                {validationMessages?.passwordCheck}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary float-end">
                                    Neues Passwort setzen
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
