import EmployeeList from "../../../components/EmployeeList/EmployeeList";
import {Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import EmployeeModal from "./EmployeeModal";
import useAPI from "../../../hooks/useAPI";

export const adminRole = {
    "roleId": 3,
    "authority": "ADMIN"
}
export const employeeRole = {
    "roleId": 2,
    "authority": "EMPLOYEE"
}

const Usermanagement = () => {
    const {data} = useAPI('admin/usermanagement', {autoCall: true})

    const [employees, setEmployees] = useState([]);
    const [showCreateEmployeeModal, setShowCreateEmployeeModal] = useState(false);

    useEffect(() => {
        if (data) {
            setEmployees(data);
        }
    }, [data]);

    const handleCreateEmployeeClick = () => {
        setShowCreateEmployeeModal(true);
    };

    return (
        <>
            <EmployeeModal
                show={showCreateEmployeeModal}
                setShow={setShowCreateEmployeeModal}
                employees={employees}
                setEmployees={setEmployees}
            />
            <div className={"bg-primary-subtle p-3 d-flex align-items-center shadow-sm mb-4"}>
                <h1 className={"container text-dark-emphasis fs-1 d-flex align-items-center gap-3"}>
                    <div
                        className={"bg-primary text-white d-flex justify-content-center align-items-center rounded-circle shadow-sm"}
                        style={{minWidth: "3.5rem", height: "3.5rem", fontSize: "2rem"}}
                    >
                        <i className="bi bi-people"></i>
                    </div>
                    <div>Verwaltung der Mitarbeiter</div>
                </h1>
            </div>
            <div className={"m-2 m-sm-3 m-md-5"}>
                <div className={"d-flex justify-content-center"}>
                    <div className="max-width-800 w-100">
                        <Button
                            size={"lg"}
                            variant={"outline-primary"}
                            className={"w-100 mb-4"}
                            onClick={handleCreateEmployeeClick}
                        >
                            Neuen Mitarbeiter anlegen
                        </Button>
                        <EmployeeList
                            employees={employees}
                            setEmployees={setEmployees}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Usermanagement;