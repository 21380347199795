import {Alert, Button, Form, Modal} from "react-bootstrap";
import React, {useMemo, useState} from "react";
import ModalWithChildren from "../modal/ModalWithChildren";
import {isUserAdmin} from "../../utils/general";
import {adminRole, employeeRole} from "../../pages/admin/usermanagement/Usermanagement";

const EditEmployeeModal = ({employees, editEmployee, setEditEmployee, show, setShow, handleSubmit, handleDelete}) => {
    const [validationMessage, setValidationMessage] = useState("");
    const [showDeleteConfirmaiton, setShowDeleteConfirmation] = useState(false);

    const handleEditChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        if (id === "admin") {
            setEditEmployee(prev => ({...prev, roles: e.target.checked ? [adminRole, employeeRole] : [employeeRole]}));
            return;
        }

        setEditEmployee(prev => ({...prev, [id]: value}));
    };

    const editParamsValid = useMemo(() => {
        // check if all required fields are filled
        if (!editEmployee.firstname) {
            setValidationMessage("Es muss ein Vorname gesetzt werden!")
            return false;
        }
        if (!editEmployee.lastname) {
            setValidationMessage("Es muss ein Nachname gesetzt werden!")
            return false;
        }
        if (!editEmployee.username) {
            setValidationMessage("Es muss ein Username gesetzt werden!")
            return false;
        }

        // check if username is unique
        const usernameExists = employees.some(employee => employee.username === editEmployee.username && employee.id !== editEmployee.id);
        if (usernameExists) {
            setValidationMessage("Ein User mit diesem username existiert bereits!")
            return false
        }

        setValidationMessage(null)
        return true;
    }, [editEmployee, employees]);

    const handleDeleteClick = () => {
        setShowDeleteConfirmation(true);
    };

    const confirmDelete = () => {
        setShowDeleteConfirmation(false);
        setShow(false);
        handleDelete(editEmployee);
    };

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Mitarbeiter bearbeiten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="firstname">
                        <Form.Label>
                            <span>Vorname</span>
                            <span className="text-danger ms-1">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={editEmployee.firstname ?? ""}
                            onChange={handleEditChange}
                            required
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className={"my-3"} controlId="lastname">
                        <Form.Label>
                            <span>Nachname</span>
                            <span className="text-danger ms-1">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={editEmployee.lastname ?? ""}
                            onChange={handleEditChange}
                            required
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className="my-3" controlId="username">
                        <Form.Label>
                            <span>Username</span>
                            <span className="text-danger ms-1">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            value={editEmployee.username ?? ""}
                            onChange={handleEditChange}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className="my-3" controlId="weeklyWorkingHours">
                        <Form.Label>
                            <span>Wochenstunden</span>
                            <span className="text-danger ms-1">*</span>
                        </Form.Label>
                        <Form.Control
                            type="number"
                            value={editEmployee.weeklyWorkingHours ?? ""}
                            onChange={handleEditChange}
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className="my-3" controlId="admin">
                        <Form.Check
                            type="checkbox"
                            label="Als Administrator festlegen"
                            checked={isUserAdmin(editEmployee) ?? false}
                            onChange={handleEditChange}
                        />
                    </Form.Group>
                    {validationMessage && <Alert variant="danger">{validationMessage}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button className={"me-auto"} variant="outline-danger" onClick={handleDeleteClick}>
                        Benutzer Löschen
                    </Button>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Abbrechen
                    </Button>
                    <Button disabled={!editParamsValid} variant={"primary"} onClick={() => {
                        setShow(false);
                        handleSubmit();
                    }}>
                        Speichern
                    </Button>
                </Modal.Footer>
            </Modal>
            <ModalWithChildren
                show={showDeleteConfirmaiton}
                setShow={setShowDeleteConfirmation}
                title={"Mitarbeiter löschen"}
                confirmButtonText={"Löschen bestätigen"}
                confirmButtonVariant={"danger"}
                paramsValid={true}
                onConfirm={confirmDelete}
                theme={"light"}
                children={<>Bist du sicher, dass du den Mitarbeiter löschen möchtest?</>}
            />
        </>
    )
        ;
}

export default EditEmployeeModal;