import Track from "../../components/track/Track";
import PauseAndStopButtons from "./PauseAndStopButtons";
import {useMemo} from "react";

const WorkdayNotEnded = ({workday, setWorkday}) => {
    const workdayStarted = useMemo(() => {
        if (!workday.startTime) return false;
        return !workday.endTime;
    }, [workday])

    return (
        <>
            <div className={"m-5 pt-3"}>
                <Track workday={workday} setWorkday={setWorkday}/>
            </div>

            <div className={`transition ${workdayStarted ? 'show' : ''}`}>
                {workdayStarted &&
                    <>
                        <div className={"m-3"}>
                            <PauseAndStopButtons
                                workday={workday}
                                setWorkday={setWorkday}
                            />
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default WorkdayNotEnded;