import DateNameMark from "../../components/dateNameMark/DateNameMark";
import WorkdayCard from "../../components/workdayCard/WorkdayCard";
import React, {useEffect, useMemo, useState} from "react";
import {millisToDecimalHours} from "../../utils/time";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import useAPI from "../../hooks/useAPI";
import AddWorkdayModal from "./AddWorkdayModal";
import {Image} from "react-bootstrap";

const Week = () => {
    const {user} = useAuth();
    const navigate = useNavigate();
    const {data: weekData, callAPI: callGet} = useAPI("workday/week", {autoCall: true})

    const [workdayList, setWorkdayList] = useState(null);
    const [timeCompList, setTimeCompList] = useState(null);
    useEffect(() => {
        if (weekData) {
            const workdays = weekData.workdays;
            // sort weekdata by startTime
            workdays.sort((a, b) => a.startTime - b.startTime);
            setWorkdayList(workdays);

            setTimeCompList(weekData.timeComps)
        }
    }, [weekData]);

    const [showAddModal, setShowAddModal] = useState(false);

    const totalWorkingTime = useMemo(() => {
        if (!workdayList) return 0;
        return workdayList.reduce((total, workday) => {
            if (!workday.startTime || !workday.endTime) return total;
            const totalBreakTime = workday.breaks.reduce((acc, curr) => acc + (curr.endTime - curr.startTime), 0);
            return total + workday.endTime - workday.startTime - totalBreakTime;
        }, 0);
    }, [workdayList]);

    const remainingWorkingTime = useMemo(() => {
        const userWeeklyWorkingMillis = user?.weeklyWorkingHours * 60 * 60 * 1000;
        const userTimeCompMillis = timeCompList?.reduce((acc, curr) => acc + curr.hours * 60 * 60 * 1000, 0) ?? 0;
        return userWeeklyWorkingMillis - totalWorkingTime - userTimeCompMillis;
    }, [totalWorkingTime, user]);

    const onAddClick = () => {
        setShowAddModal(true)
    }

    return (
        <>
            <AddWorkdayModal
                show={showAddModal}
                setShow={setShowAddModal}
                onCreated={callGet}
            />
            <div className={"text-light h-100 overflow-y-auto overflow-x-hidden max-width-600"}>
                <div className={"m-3 d-flex justify-content-between"}>
                    <DateNameMark date={new Date()} firstname={user?.firstname} lastname={user?.lastname}/>
                    <div
                        className={`bg-black d-flex align-items-center justify-content-center rounded-4 shadow cursor-pointer`}
                        style={{width: "45px", height: "45px"}}
                        role={"button"}
                        onClick={onAddClick}
                    >
                        <i className="bi bi-plus-lg"></i>
                    </div>
                </div>

                {
                    workdayList ?
                        <>
                            <div className={"d-flex m-2 gap-2 mt-5"}>
                                <div className={"w-100 rounded-4 p-3 bg-white-02 shadow"}>
                                    <div className={"d-flex gap-2"}>
                                        <div
                                            className={"bg-success rounded-3 d-flex justify-content-center align-items-center shadow"}
                                            style={{
                                                minWidth: "40px",
                                                height: "40px",
                                            }}
                                        >
                                            <i className="bi bi-check mt-1" style={{fontSize: "2.2em"}}></i>
                                        </div>
                                        <div>
                                            <h2 style={{fontSize: "1.1em"}} className={"fw-bold text-shadow-4"}>Wochen
                                                Arbeitszeit</h2>
                                        </div>
                                    </div>
                                    <div className={"mt-2"}>
                                        <span className={"fs-1 fw-bold"}>{millisToDecimalHours(totalWorkingTime)}</span>
                                        <span className={"text-light-emphasis ms-1"}>h</span>
                                    </div>
                                </div>
                                <div className={"w-100 rounded-4 p-3 bg-white-02 shadow"}>
                                    <div className={"d-flex gap-2"}>
                                        <div
                                            className={"bg-primary rounded-3 d-flex justify-content-center align-items-center shadow"}
                                            style={{
                                                minWidth: "40px",
                                                height: "40px",
                                            }}
                                        >
                                            <i className="bi bi-clock-history"
                                               style={{fontSize: "1.4em", marginTop: 2}}></i>
                                        </div>
                                        <div>
                                            <h2 style={{fontSize: "1.1em"}}
                                                className={"fw-bold text-shadow-4"}>Verbleibende
                                                Arbeitszeit</h2>
                                        </div>
                                    </div>
                                    <div className={"mt-2"}>
                                        <span
                                            className={"fs-1 fw-bold"}>{millisToDecimalHours(remainingWorkingTime)}</span>
                                        <span className={"text-light-emphasis ms-1"}>h</span>
                                    </div>
                                </div>
                            </div>

                            {
                                timeCompList?.length > 0 &&
                                <div className={"m-2 rounded-4 p-3 bg-white-02 shadow"}>
                                    <div className={"d-flex"}>
                                        <Image
                                            src={"/time_comp.png"}
                                            className={"rounded-circle position-relative shadow-sm"}
                                            width={50}
                                        />
                                        <div className={"mx-2 d-flex align-items-center"}>
                                        <span
                                            className={"fs-1 fw-bold"}>{timeCompList?.reduce((acc, curr) => acc + curr.hours, 0)}</span>
                                            <span
                                                className={"text-light-emphasisms-1 ms-1"}>h Zeitausgleich diese Woche</span>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className={"m-2 mt-5"}>
                                <div>
                                    <h1 className={"fs-3 mb-3 fw-bold"}>Arbeitszeiten</h1>
                                </div>
                                <div>
                                    {workdayList.map((workday, index) => {
                                        return (
                                            <div key={index} className={"my-2"}>
                                                <WorkdayCard
                                                    onClick={() => navigate(`/workday?id=${workday.id}`)}
                                                    workday={workday}/>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                        :
                        <div className={"d-flex justify-content-center align-items-center h-100"}>
                            <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                }
            </div>
        </>
    )
}

export default Week;