import React from 'react';
import {getFormattedTimeNoSeconds} from "../../utils/time";
import "./EmployeeDayHistoryBar.css";

const calculateSegments = (workday) => {
    const segments = [];
    let currentTime = workday.startTime;
    const now = Date.now();

    for (const brk of workday.breaks) {
        if (currentTime < brk.startTime) {
            segments.push({type: 'work', startTime: currentTime, endTime: brk.startTime});
        }
        segments.push({type: 'break', startTime: brk.startTime, endTime: brk.endTime ? brk.endTime : now});
        currentTime = brk.endTime ? brk.endTime : now;
    }

    if (workday.breaks.length > 0) {
        const lastBreak = workday.breaks[workday.breaks.length - 1];
        if (lastBreak.endTime) {
            if (!workday.endTime || currentTime < workday.endTime) {
                segments.push({
                    type: 'work',
                    startTime: currentTime,
                    endTime: workday.endTime ? workday.endTime : now,
                });
            }
        }
    } else {
        if (!workday.endTime || currentTime < workday.endTime) {
            segments.push({
                type: 'work',
                startTime: currentTime,
                endTime: workday.endTime ? workday.endTime : now,
            });
        }
    }

    return segments;
};

const EmployeeDayHistoryBar = ({workday}) => {
    const segments = calculateSegments(workday);
    const totalTime = (workday.endTime ? workday.endTime : Date.now()) - workday.startTime;

    return (
        <div className="progress w-100 position-relative" style={{height: '2rem', display: 'flex'}}>
            {segments.map((segment, index) => {
                const segmentTime = segment.endTime - segment.startTime;
                const segmentPercentage = (segmentTime / totalTime) * 100;
                const segmentClass = segment.type === 'work' ? 'bg-success' : 'bg-warning';

                return (
                    <div
                        key={index}
                        className={`progress-bar flex-row align-items-center ${segmentClass}`}
                        style={{width: `${segmentPercentage}%`, height: '2rem'}}
                    >
                        {index === 0 && (
                            <span className="time-label start-time text-dark">
                                {getFormattedTimeNoSeconds(segment.startTime)}
                            </span>
                        )}
                        {index === segments.length - 1 && workday?.endTime && (
                            <span className="time-label end-time text-dark">
                                {getFormattedTimeNoSeconds(segment.endTime)}
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default EmployeeDayHistoryBar;
