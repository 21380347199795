import React from "react";
import {unixToFormattedDateWithDay} from "../../utils/time";
import "./employeeRequests.css";
import {Image} from "react-bootstrap";

const CompactSickLeaveCard = ({sickLeaveRequest, onClick}) => {
    return (
        <div
            className="card fs-90 rounded-4 text-white"
            style={{backgroundColor: "rgba(255,255,255,0.25)"}}
            onClick={onClick}
        >
            <div className={"d-flex"}>
                <div className="card-body verticalTimeline d-flex flex-column gap-2">
                    <div className="verticalTimeline-dot verticalTimeline-dot-warning fw-bold">
                        <div className={"ms-2"}>
                            {unixToFormattedDateWithDay(sickLeaveRequest.startDate)}
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={"verticalTimeline-line verticalTimeline-line-warning"}></div>
                        <div className={"ms-2 fst-italic"} style={{whiteSpace: "pre-line"}}>
                            {sickLeaveRequest.notes}
                        </div>
                    </div>
                    <div className="verticalTimeline-dot verticalTimeline-dot-warning fw-bold">
                        <div className={"ms-2"}>
                            {unixToFormattedDateWithDay(new Date(sickLeaveRequest.endDate).getTime())}
                        </div>
                    </div>
                </div>
                <div className={"m-3"}>
                    <Image
                        src={"/sick_leave.png"}
                        className={"rounded-circle position-relative shadow-sm"}
                        style={{top: -3}}
                        width={60}
                    />
                </div>
            </div>
        </div>
    );
}

export default CompactSickLeaveCard;