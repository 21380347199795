import {Button, Modal} from "react-bootstrap";

const ModalWithChildren = (
    {
        show,
        setShow,
        title,
        confirmButtonText,
        confirmButtonVariant,
        paramsValid,
        onConfirm,
        theme = "dark",
        children,
        centered = false
    }) => {
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            data-bs-theme={theme}
            className={`${theme === "dark" && "text-light"} bg-black-75`}
            centered={centered}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Abbrechen
                </Button>
                <Button disabled={!paramsValid} variant={confirmButtonVariant} onClick={() => {
                    setShow(false);
                    onConfirm();
                }}>
                    {confirmButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalWithChildren;