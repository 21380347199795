import ModalWithChildren from "../../../components/modal/ModalWithChildren";
import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Form, InputGroup} from "react-bootstrap";
import {isUserAdmin} from "../../../utils/general";
import {adminRole, employeeRole} from "./Usermanagement";
import useAPI from "../../../hooks/useAPI";

const EmployeeModal = ({show, setShow, employees, setEmployees}) => {
    const {data, callAPI} = useAPI('admin/usermanagement', {method: "POST"});

    const initialEmployee = {roles: [employeeRole]};

    const [newEmployee, setNewEmployee] = useState(initialEmployee);
    const [validationMessage, setValidationMessage] = useState("");
    const [touchedFields, setTouchedFields] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    const handleFieldsChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        if (id === "admin") {
            setNewEmployee(prev => ({...prev, roles: e.target.checked ? [adminRole, employeeRole] : [employeeRole]}));
            return;
        }

        setNewEmployee(prev => ({...prev, [id]: value}));
        setTouchedFields(prev => ({...prev, [id]: true}));
    };

    const createEmployee = () => {
        if (!paramsValid) return;

        callAPI({body: newEmployee}).then((respData) => {
            setEmployees(prev => [...prev, {...newEmployee, id: respData.user.userId}]);
            setNewEmployee(initialEmployee);
            setShow(false);
        });
    };

    useEffect(() => {
        if (!show) {
            setNewEmployee(initialEmployee);
            setTouchedFields({});
            setValidationMessage("");
        }
    }, [show]);

    const paramsValid = useMemo(() => {
        // check if all required fields are filled
        let isValid = true;
        if (!newEmployee.firstname) {
            isValid = false;
            if (touchedFields.firstname) {
                setValidationMessage("Es muss ein Vorname gesetzt werden!")
            }
        }
        if (!newEmployee.lastname) {
            isValid = false;
            if (touchedFields.lastname) {
                setValidationMessage("Es muss ein Nachname gesetzt werden!")
            }
        }
        if (!newEmployee.username) {
            isValid = false;
            if (touchedFields.username) {
                setValidationMessage("Es muss ein Username gesetzt werden!")
            }
        }
        if (!newEmployee.password) {
            isValid = false;
            if (touchedFields.password) {
                setValidationMessage("Es muss ein Passwort gesetzt werden!")
            }
        }

        // check if username is unique
        const usernameExists = employees.some(employee => employee.username === newEmployee.username);
        if (usernameExists) {
            isValid = false;
            if (touchedFields.username) {
                setValidationMessage("Ein User mit diesem username existiert bereits!")
            }
        }

        if (isValid) {
            setValidationMessage(null)
        }
        return isValid;
    }, [newEmployee, employees, touchedFields]);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <ModalWithChildren
            show={show}
            setShow={setShow}
            title={"Neuen Mitarbeiter anlegen"}
            confirmButtonText={"Mitarbeiter anlegen"}
            confirmButtonVariant={"primary"}
            paramsValid={paramsValid}
            onConfirm={createEmployee}
            theme={"light"}
        >
            <Form.Group controlId="firstname">
                <Form.Label>
                    <span>Vorname</span>
                    <span className="text-danger ms-1">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    value={newEmployee.firstname ?? ""}
                    onChange={handleFieldsChange}
                    onBlur={() => setTouchedFields(prev => ({...prev, firstname: true}))}
                    required
                    autoComplete="off"
                />
            </Form.Group>
            <Form.Group className="my-3" controlId="lastname">
                <Form.Label>
                    <span>Nachname</span>
                    <span className="text-danger ms-1">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    value={newEmployee.lastname ?? ""}
                    onChange={handleFieldsChange}
                    onBlur={() => setTouchedFields(prev => ({...prev, lastname: true}))}
                    required
                    autoComplete="off"
                />
            </Form.Group>
            <Form.Group className="my-3" controlId="username">
                <Form.Label>
                    <span>Username</span>
                    <span className="text-danger ms-1">*</span>
                </Form.Label>
                <Form.Control
                    type="text"
                    value={newEmployee.username ?? ""}
                    onChange={handleFieldsChange}
                    onBlur={() => setTouchedFields(prev => ({...prev, username: true}))}
                    autoComplete="off"
                />
            </Form.Group>
            <Form.Group className="my-3" controlId="password">
                <Form.Label>
                    <span>Passwort</span>
                    <span className="text-danger ms-1">*</span>
                </Form.Label>
                <InputGroup>
                    <Form.Control
                        type={showPassword ? "text" : "password"}
                        value={newEmployee.password ?? ""}
                        onChange={handleFieldsChange}
                        onBlur={() => setTouchedFields(prev => ({...prev, password: true}))}
                        autoComplete="off"
                    />
                    <Button variant="outline-secondary" onClick={toggleShowPassword}>
                        <i className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                    </Button>
                </InputGroup>
                <Form.Text>Das Passwort kann der Benutzer im nachhinein selbst ändern.</Form.Text>
            </Form.Group>
            <Form.Group className="my-3" controlId="weeklyWorkingHours">
                <Form.Label>
                    <span>Wochenstunden</span>
                    <span className="text-danger ms-1">*</span>
                </Form.Label>
                <Form.Control
                    type="number"
                    step={0.5}
                    value={newEmployee.weeklyWorkingHours ?? 38.5}
                    onChange={handleFieldsChange}
                    autoComplete="off"
                />
            </Form.Group>
            <Form.Group className="my-3" controlId="admin">
                <Form.Check
                    type="checkbox"
                    label="Als Administrator festlegen"
                    checked={isUserAdmin(newEmployee)}
                    onChange={handleFieldsChange}
                />
            </Form.Group>
            {validationMessage && <Alert variant="danger">{validationMessage}</Alert>}
        </ModalWithChildren>
    );
};

export default EmployeeModal;
