import {Navigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";

const Home = () => {
    const {isAuthenticated} = useAuth();

    return (
        <>
            {isAuthenticated === undefined ?
                <div className={"d-flex justify-content-center align-items-center h-100"}>
                    <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                : isAuthenticated ?
                    <Navigate to={"/tracking"}/>
                    :
                    <Navigate to={"/login"}/>
            }
        </>
    );
};

export default Home;