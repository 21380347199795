import {millisToTimeString} from "../../utils/time";
import {calculateBreakTime, calculateWorkingTime} from "../../utils/general";

const WorkdayDrawerDayTimes = ({props}) => {
    return (
        <div className={"d-flex gap-2 text-white"}>
            <div className={"w-100 rounded-4 p-3 bg-black-50 shadow"}>
                <div className={"d-flex gap-2"}>
                    <div
                        className={"bg-success rounded-3 d-flex justify-content-center align-items-center"}
                        style={{
                            minWidth: "40px",
                            height: "40px",
                        }}
                    >
                        <i className="bi bi-check mt-1" style={{fontSize: "2.2em"}}></i>
                    </div>
                    <div>
                        <h2 style={{fontSize: "1.1em"}} className={"fw-bold"}>Tages Arbeitszeit</h2>
                    </div>
                </div>
                <div className={"fs-1 fw-bold mt-2"}>
                    {millisToTimeString(calculateWorkingTime(props.drawerData.workday))}
                </div>
            </div>

            <div className={"w-100 rounded-4 p-3 bg-black-50 shadow"}>
                <div className={"d-flex gap-2"}>
                    <div
                        className={"bg-primary rounded-3 d-flex justify-content-center align-items-center"}
                        style={{
                            minWidth: "40px",
                            height: "40px",
                        }}
                    >
                        <i className="bi bi-clock-history"
                           style={{fontSize: "1.4em", marginTop: 2}}></i>
                    </div>
                    <div>
                        <h2 style={{fontSize: "1.1em"}} className={"fw-bold"}>Tages Pausenzeit</h2>
                    </div>
                </div>
                <div className={"fs-1 fw-bold mt-2"}>
                    {millisToTimeString(calculateBreakTime(props.drawerData.workday))}
                </div>
            </div>
        </div>
    );
}

export default WorkdayDrawerDayTimes;