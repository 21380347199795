import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from '../../context/AuthContext';

const ProtectedRoute = () => {
    const {isAuthenticated} = useAuth();

    if (isAuthenticated === undefined) return null;

    return isAuthenticated ? <Outlet/> : <Navigate to="/login"/>;
};

export default ProtectedRoute;