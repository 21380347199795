import React from "react";
import {unixToFormattedDateWithDay} from "../../utils/time";
import "./employeeRequests.css";
import {Image} from "react-bootstrap";

const CompactTimeCompCard = ({timeCompRequest, onClick}) => {
    const states = {
        PENDING: (
            <div className={"bg-secondary rounded-top-4 text-center fw-bold py-1"}>
                Ausstehend
            </div>
        ),
        ACCEPTED: (
            <div className={"bg-success rounded-top-4 text-center fw-bold py-1"}>
                Genehmigt
            </div>
        ),
        DECLINED: (
            <div className={"bg-danger rounded-top-4 text-center fw-bold py-1"}>
                Abgelehnt
            </div>
        ),
    }

    return (
        <div
            className="card fs-90 rounded-4 text-white"
            style={{backgroundColor: "rgba(255,255,255,0.25)"}}
            onClick={onClick}
        >
            {states[timeCompRequest.state]}
            <div className={"d-flex"}>
                <div className="card-body verticalTimeline d-flex flex-column gap-2">
                    <div className="verticalTimeline-dot verticalTimeline-dot-primary fw-bold">
                        <div className={"ms-2"}>
                            {unixToFormattedDateWithDay(timeCompRequest.date)}
                        </div>
                    </div>
                    <div className="verticalTimeline-dot verticalTimeline-dot-primary fw-bold">
                        <div className={"ms-2"}>
                            {timeCompRequest.hours} Stunden
                        </div>
                    </div>
                    <div className={"d-flex"}>
                        <div className={"verticalTimeline-line verticalTimeline-line-primary"}></div>
                        <div className={"ms-2 fst-italic"} style={{whiteSpace: "pre-line"}}>
                            {timeCompRequest.notes}
                        </div>
                    </div>
                </div>
                <div className={"m-3"}>
                    <Image
                        src={"/time_comp.png"}
                        className={"rounded-circle position-relative shadow-sm"}
                        width={60}
                    />
                </div>
            </div>
        </div>
    );
}

export default CompactTimeCompCard;