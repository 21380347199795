import React, {useMemo} from "react";
import {unixToFormattedDateWithDay} from "../../utils/time";

const TimeCompRequestCard = ({timeCompRequest, handleApprove, handleDecline}) => {
    const state = useMemo(() => {
        let term;
        let bgColorClassName;
        switch (timeCompRequest.state) {
            case 0:
                term = "Offen";
                bgColorClassName = "bg-warning";
                break;
            case 1:
                term = "Genehmigt";
                bgColorClassName = "bg-success";
                break;
            case 2:
                term = "Abgelehnt";
                bgColorClassName = "bg-danger";
                break;
            default:
                term = "Unbekannt";
                bgColorClassName = "bg-secondary";
                break;
        }

        return {id: timeCompRequest.state, term, bgColorClassName};
    }, [timeCompRequest.state])

    return (
        <div className="card shadow-sm bg-light-subtle">
            <div className="card-body">
                <h5 className="card-title pb-2 d-flex gap-2 align-items-center">
                    <div
                        className={`${state.bgColorClassName} rounded-circle shadow-sm`}
                        style={{
                            height: "0.6em",
                            minWidth: "0.6em",
                        }}
                    ></div>
                    <div>Zeitausgleich Antrag</div>
                    <div className={`badge ${state.bgColorClassName}`}>{state.term}</div>
                    {state.id === 0 &&
                        <div className={"ms-auto row"}>
                            <div
                                className={"col m-1 btn btn-sm btn-outline-danger fw-bold"}
                                role={"button"}
                                onClick={() => handleDecline(timeCompRequest.id)}
                            >
                                <span className={"d-none d-sm-block"}>Ablehnen</span>
                                <span className={"d-block d-sm-none"}><i className="bi bi-x-lg"></i></span>

                            </div>
                            <div
                                className={"col m-1 btn btn-sm btn-outline-success fw-bold"}
                                role={"button"}
                                onClick={() => handleApprove(timeCompRequest.id)}
                            >
                                <span className={"d-none d-sm-block"}>Genehmigen</span>
                                <span className={"d-block d-sm-none"}><i className="bi bi-check-lg"></i></span>
                            </div>
                        </div>
                    }
                </h5>

                <div className={"row"}>
                    <div className="col-12 col-xl-3 d-flex mb-2">
                        <div
                            className="bg-success rounded-circle d-flex justify-content-center align-items-center shadow-sm"
                            style={{minWidth: "3em", height: "3em"}}
                        >
                            <i className="bi bi-person text-secondary"
                               style={{fontSize: "1.75em"}}></i>
                        </div>
                        <div className="ms-3 mt-1 ">
                            <h6 className="mb-0">{timeCompRequest.user.name}</h6>
                            <span className="text-muted">{timeCompRequest.user.username}</span>
                        </div>
                    </div>

                    <div className="col-12 col-xl-3 mb-2">
                        <div className={"text-secondary"}>Datum:</div>
                        <div className="fw-bold">{unixToFormattedDateWithDay(timeCompRequest.date)}</div>
                    </div>

                    <div className="col-12 col-xl-3 mb-2">
                        <div className={"text-secondary"}>Stunden:</div>
                        <div className="fw-bold">{timeCompRequest.hours}</div>
                    </div>

                    <div className="col-12 col-xl-3">
                        <div className={"text-secondary"}>Zusätzliche Informationen:</div>
                        <div className={"fst-italic"}>{timeCompRequest.notes}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TimeCompRequestCard;