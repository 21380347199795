import DateNameMark from "../../components/dateNameMark/DateNameMark";
import {getFormattedDateWithDay, millisToTimeString} from "../../utils/time";
import {useEffect, useMemo, useState} from "react";
import {calculateTotalWorkingTime} from "../../utils/general";
import WorkdayTimeHistory from "../../components/workdayTimeHistory/WorkdayTimeHistory";
import {useAuth} from "../../context/AuthContext";
import useAPI from "../../hooks/useAPI";
import {useSearchParams} from "react-router-dom";
import EditWorkdayModal from "./EditWorkdayModal";
import {useToast} from "../../context/ToastContext";

const Workday = () => {
    const {user} = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const renderToast = useToast();

    const {data: workdayData} = useAPI('workday/' + searchParams.get("id"), {autoCall: true});
    const {callAPI: callEdit} = useAPI('workday/edit', {method: 'PUT'});

    const [workday, setWorkday] = useState(null);
    useEffect(() => {
        if (workdayData) {
            setWorkday(workdayData);
        }
    }, [workdayData]);

    const [showEditModal, setShowEditModal] = useState(false);
    const [editWorkday, setEditWorkday] = useState(null);

    const totalWorkingTime = useMemo(() => {
        if (!workday) return 0;
        return calculateTotalWorkingTime(workday);
    }, [workday]);

    const totalBreakTime = useMemo(() => {
        if (!workday?.breaks?.length) return 0;
        return workday.breaks.reduce((acc, curr) => acc + (curr.endTime - curr.startTime), 0);
    }, [workday]);

    const onEditClick = () => {
        setShowEditModal(true);
        setEditWorkday(workday)
    };

    const onEditSubmit = () => {
        callEdit({body: editWorkday}).then(data => {
            setWorkday(data);
            setEditWorkday(null);
            setShowEditModal(false);
            renderToast("Arbeitstag erfolgreich bearbeitet", "bg-success");
        }).catch((err) => {
            renderToast(err.response.data, "bg-danger");
        });
    };

    return (
        <div className={"text-light h-100 overflow-y-auto overflow-x-hidden max-width-600"}>
            <div className={"m-3 d-flex justify-content-between"}>
                <DateNameMark date={new Date()} firstname={user?.firstname} lastname={user?.lastname}/>
                <div
                    className={`text-light`}
                    role={"button"}
                    onClick={onEditClick}
                >
                    <i className="bi bi-pencil-square me-2"></i>
                    <span>Arbeitstag Bearbeiten</span>
                </div>
            </div>

            {
                workday ?
                    <>
                        <EditWorkdayModal
                            show={showEditModal}
                            setShow={setShowEditModal}
                            handleSubmit={onEditSubmit}
                            workday={editWorkday}
                            setWorkday={setEditWorkday}
                        />

                        <div className={"bg-black-50 rounded-4 m-2 shadow"}>
                            <h1
                                className={"text-center py-3 fs-4 fw-bold"}
                                style={{
                                    textShadow: "0 0 5px rgba(0, 0, 0, 0.5)"
                                }}
                            >
                                {getFormattedDateWithDay(new Date(workday.startTime))}
                            </h1>
                        </div>

                        <div className={"d-flex m-2 gap-2"}>
                            <div className={"w-100 rounded-4 p-3 bg-black-50 shadow"}>
                                <div className={"d-flex gap-2"}>
                                    <div
                                        className={"bg-success rounded-3 d-flex justify-content-center align-items-center"}
                                        style={{
                                            minWidth: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <i className="bi bi-check mt-1" style={{fontSize: "2.2em"}}></i>
                                    </div>
                                    <div>
                                        <h2 style={{fontSize: "1.1em"}} className={"fw-bold"}>Tages Arbeitszeit</h2>
                                    </div>
                                </div>
                                <div className={"fs-1 fw-bold mt-2"}>
                                    {millisToTimeString(totalWorkingTime)}
                                </div>
                            </div>

                            <div className={"w-100 rounded-4 p-3 bg-black-50 shadow"}>
                                <div className={"d-flex gap-2"}>
                                    <div
                                        className={"bg-primary rounded-3 d-flex justify-content-center align-items-center"}
                                        style={{
                                            minWidth: "40px",
                                            height: "40px",
                                        }}
                                    >
                                        <i className="bi bi-clock-history"
                                           style={{fontSize: "1.4em", marginTop: 2}}></i>
                                    </div>
                                    <div>
                                        <h2 style={{fontSize: "1.1em"}} className={"fw-bold"}>Tages Pausenzeit</h2>
                                    </div>
                                </div>
                                <div className={"fs-1 fw-bold mt-2"}>
                                    {millisToTimeString(totalBreakTime)}
                                </div>
                            </div>
                        </div>

                        <div className={`mt-5`}>
                            <div className={"m-2 mt-5 bg-black px-3 py-2 rounded-4 lh-sm"}>
                                <h3 className={"lead my-2"}>Tätigkeiten</h3>
                                <hr/>
                                <div>
                                    {workday.tasks.split('\n').map((task, index) => (
                                        <p className={"my-2"} key={index}>{task}</p>
                                    ))}
                                </div>
                            </div>
                            <div className={"m-1 mt-5 mb-4"}>
                                <h1 className={"fs-3 mb-3 text-center fw-bold"}>Verlauf des Tages</h1>
                                <WorkdayTimeHistory workday={workday}/>
                            </div>
                        </div>
                    </>
                    :
                    <div className={"d-flex justify-content-center align-items-center h-100"}>
                        <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
            }
        </div>
    );
};

export default Workday;