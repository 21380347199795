import React, {useEffect, useState} from "react";
import {styled, SwipeableDrawer} from "@mui/material";
import WorkdayTimeHistory from "../workdayTimeHistory/WorkdayTimeHistory";
import PropTypes from "prop-types";
import {grey} from "@mui/material/colors";
import WorkdayDrawerDayTimes from "./WorkdayDrawerDayTimes";
import Spinner from "../loading/Spinner";

const WorkdayDrawer = (props) => {
    const [drawerHeight, setDrawerHeight] = useState('50vh');
    const Puller = styled('div')(({theme}) => ({
        width: 50,
        height: 5,
        backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
        borderRadius: 3,
        position: 'absolute',
        top: 12,
        left: 'calc(50% - 25px)',
    }));

    useEffect(() => {
        const handleResize = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            setDrawerHeight(`${vh * 50}px`); // 50% of the viewport height
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <SwipeableDrawer
            anchor={"bottom"}
            open={props.drawerData !== null}
            onClose={props.onClose}
            onOpen={() => {
            }}
            disableBackdropTransition={!props.iOS}
            disableDiscovery={props.iOS}
            PaperProps={{
                className: "bg-gradient rounded-top-5",
                style: {minHeight: drawerHeight, maxHeight: 'calc(var(--vh, 1vh) * 90)'} // Set initial minHeight and allow expansion up to maxHeight
            }}
        >
            <div className={"py-3"} onClick={props.onClose}>
                <Puller/>
            </div>

            {
                props.drawerData?.workday ?
                    <div className={"container overflow-y-auto"}>
                        {props.drawerData?.workday &&
                            <>
                                <WorkdayDrawerDayTimes props={props}/>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={`mt-5 mt-md-4`}>
                                            <div className={"text-white bg-black px-3 py-2 rounded-4 lh-sm"}>
                                                <h3 className={"lead my-2"}>Tätigkeiten</h3>
                                                <hr/>
                                                <div>
                                                    {props.drawerData?.workday.tasks.split('\n').map((task, index) => (
                                                        <p className={"my-2"} key={index}>{task}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"mt-5 mt-md-4 mb-4 col-12 col-md-6"}>
                                        <h1 className={"fs-3 mb-3 text-center text-white fw-bold"}>Verlauf des
                                            Tages</h1>
                                        <WorkdayTimeHistory workday={props.drawerData?.workday}/>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    :
                    <Spinner/>
            }
        </SwipeableDrawer>
    );
}

WorkdayDrawer.propTypes = {
    drawerData: PropTypes.any,
    onClose: PropTypes.func,
    iOS: PropTypes.bool
};

export default WorkdayDrawer;