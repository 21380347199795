import {useEffect, useState} from 'react';
import axios from 'axios';

const useAPI = (endpoint, {method = 'GET', params = {}, autoCall = false} = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const baseURL = process.env.REACT_APP_BASE_URL;

    const callAPI = async ({endpointAddition = "", body = null} = {}) => {
        setLoading(true);
        setError(null);
        const token = localStorage.getItem("token");
        const headers = {
            Authorization: token ? `Bearer ${token}` : undefined,
        };

        try {
            const response = await axios({
                method,
                url: baseURL + endpoint + endpointAddition,
                data: body,
                params,
                headers,
            });
            setData(response.data);
            setLoading(false);
            return response.data;
        } catch (err) {
            setError(err);
            setLoading(false);

            if (err.response && err.response.status === 401 && localStorage.getItem('token')) {
                localStorage.removeItem('token');
                window.location.reload();
            }

            if (autoCall) {
                return err;
            } else {
                throw err;
            }
        }
    };

    useEffect(() => {
        if (autoCall) {
            callAPI();
        }
    }, []);

    return {data, loading, error, callAPI};
};

export default useAPI;
