import {Link, useLocation} from "react-router-dom";
import "./adminNav.css";
import {NAVIGATION_HEIGHT} from "../navigation/Navigation";
import {Image} from "react-bootstrap";

const items = [
    {
        name: "Übersicht",
        icon: <i className="bi bi-calendar-check"></i>,
        path: "/admin"
    },
    {
        name: "Anträge",
        icon: <i className="bi bi-card-checklist"></i>,
        path: "/admin/requests"
    },
    {
        name: "Userverwaltung",
        icon: <i className="bi bi-people"></i>,
        path: "/admin/usermanagement"
    },
    // {
    //     name: "Einstellungen",
    //     icon: <i className="bi bi-sliders"></i>,
    //     path: "/admin/settings"
    // }
];

const AdminNavigation = () => {
    const location = useLocation();
    const {pathname} = location;

    return (
        <>
            {/* DESKTOP SIDE NAVIGATION */}
            <div className={"shadow px-4 py-3 h-100 d-none d-lg-flex flex-column justify-content-between"}
                 style={{minWidth: 300}}
            >
                <div className={"d-flex flex-column align-items-center"}>
                    <Image src="/logo192.png" alt="Strenn Logo" className={"mb-4"} width={100} fluid/>
                    <div className={"d-flex align-items-center w-100"}>
                        <Link
                            className={`sideNav-heading btn btn-link w-100 d-flex gap-3 text-decoration-none p-3 text-dark`}
                            to={`/admin`}
                        >
                            <div><i className="bi bi-gear"></i></div>
                            <div className={"navItemText"}>
                                Administrator
                            </div>
                        </Link>
                    </div>
                </div>

                <ul className={"list-unstyled m-0"}>
                    {items.map((item, index) => {
                        return (
                            <li key={index} className={"d-flex align-items-center"}>
                                <Link
                                    className={`nav-item btn btn-link w-100 d-flex gap-3 text-decoration-none p-3 
                                    ${pathname === item.path ? "text-primary" : "text-dark "}`}
                                    to={`${item.path}`}
                                >
                                    <div>{item.icon}</div>
                                    <div className={"navItemText"}>
                                        {item.name}
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
                </ul>

                <div className={"d-flex align-items-center"}>
                    <Link
                        className={`nav-item btn btn-link w-100 d-flex gap-3 text-decoration-none p-3 text-dark`}
                        to={`/`}
                    >
                        <div><i className="bi bi-house"></i></div>
                        <div className={"navItemText"}>
                            Zurück zur Startseite
                        </div>
                    </Link>
                </div>
            </div>

            {/* MOBILE BOTTOM NAVIGATION */}
            <div className={"bg-white navigationComponent fixed-bottom shadow-lg rounded-top-4 d-block d-lg-none"}
                 style={{height: NAVIGATION_HEIGHT}}>
                <ul className={"h-100 d-flex flex-row justify-content-around list-unstyled mx-2 my-0"}>
                    {items.map((item, index) => {
                        return (
                            <li key={index} className={"d-flex align-items-center"}>
                                <Link
                                    className={`text-decoration-none text-center ${pathname === item.path ? "text-primary" : "text-dark "}`}
                                    to={`${item.path}`}>
                                    <div>{item.icon}</div>
                                    {pathname === item.path ? <div className={"navItemText"}>{item.name}</div> : null}
                                </Link>
                            </li>
                        );
                    })}
                    <li className={"d-flex align-items-center"}>
                        <Link
                            className={`text-decoration-none text-center text-dark`} to={`/`}>
                            <div><i className="bi bi-house"></i></div>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default AdminNavigation;
