export const calculateTotalWorkingTime = (workday) => {
    if (!workday.startTime) return null;
    if (!workday.endTime) return null;
    const totalBreakTime = workday.breaks?.length ?
        workday.breaks.reduce((acc, curr) => acc + (curr.endTime - curr.startTime), 0) : 0;
    return workday.endTime - workday.startTime - totalBreakTime;
}

export const calculateWorkingTime = (workday) => {
    if (!workday.startTime) return null;

    const endtime = workday.endTime ? workday.endTime : new Date().getTime();

    const totalBreakTime = workday.breaks?.length ?
        workday.breaks.reduce((acc, curr) =>
            acc + ((curr.endTime ? curr.endTime : new Date().getTime()) - curr.startTime
            ), 0)
        : 0;

    return endtime - workday.startTime - totalBreakTime;
}

export const calculateBreakTime = (workday) => {
    if (!workday.breaks?.length) return 0;
    return workday.breaks.reduce((acc, curr) => {
        return acc + ((curr.endTime ?? new Date().getTime()) - curr.startTime);
    }, 0);
}

export const isUserAdmin = (user) => {
    if (user.authorities) return user.authorities.some(role => role.authority === "ADMIN");
    return user?.roles?.some(role => role.authority === "ADMIN");
}

export const isUserEmployee = (user) => {
    if (user.authorities) return user.authorities.some(role => role.authority === "EMPLOYEE");
    return user?.roles?.some(role => role.authority === "EMPLOYEE");
}

export const userTypeString = (user) => {
    if (isUserAdmin(user)) return "Administrator";
    if (isUserEmployee(user)) return "Angestellter";
    return "Nutzer";
};