import {useMemo} from "react";
import {millisToTimeString} from "../../utils/time";
import {calculateTotalWorkingTime} from "../../utils/general";

const WorkdayEnded = ({workday}) => {
    const totalWorkingTime = useMemo(() => {
        return calculateTotalWorkingTime(workday);
    }, [workday]);

    const totalBreakTime = useMemo(() => {
        if (!workday.breaks?.length) return 0;
        return workday.breaks.reduce((acc, curr) => acc + (curr.endTime - curr.startTime), 0);
    }, [workday]);

    return (
        <div className={"mt-4"}>
            <div className={"bg-white-02 rounded-4 m-2 shadow"}>
                <h1
                    className={"text-center py-3 fs-4 fw-bold"}
                    style={{
                        textShadow: "0 0 5px rgba(0, 0, 0, 0.5)"
                    }}
                >
                    Arbeitstag beendet
                </h1>
            </div>

            <div className={"d-flex m-2 gap-2"}>
                <div className={"w-100 rounded-4 p-3 bg-white-02 shadow"}>
                    <div className={"d-flex gap-2"}>
                        <div
                            className={"bg-success rounded-3 d-flex justify-content-center align-items-center"}
                            style={{
                                minWidth: "40px",
                                height: "40px",
                            }}
                        >
                            <i className="bi bi-check mt-1" style={{fontSize: "2.2em"}}></i>
                        </div>
                        <div>
                            <h2 style={{fontSize: "1.1em"}} className={"fw-bold"}>Heutige Arbeitszeit</h2>
                        </div>
                    </div>
                    <div className={"fs-1 fw-bold mt-2"}>
                        {millisToTimeString(totalWorkingTime)}
                    </div>
                </div>

                <div className={"w-100 rounded-4 p-3 bg-white-02 shadow"}>
                    <div className={"d-flex gap-2"}>
                        <div
                            className={"bg-primary rounded-3 d-flex justify-content-center align-items-center"}
                            style={{
                                minWidth: "40px",
                                height: "40px",
                            }}
                        >
                            <i className="bi bi-clock-history" style={{fontSize: "1.4em", marginTop: 2}}></i>
                        </div>
                        <div>
                            <h2 style={{fontSize: "1.1em"}} className={"fw-bold"}>Gesamte Pausenzeit</h2>
                        </div>
                    </div>
                    <div className={"fs-1 fw-bold mt-2"}>
                        {millisToTimeString(totalBreakTime)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkdayEnded;