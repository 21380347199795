import React, {createContext, useContext, useState} from 'react';

const ToastContext = createContext();

export const ToastProvider = ({children}) => {
    const [toast, setToast] = useState({message: '', visible: false});

    const renderToast = (message, className, time = 10000) => {
        setToast({message, visible: true, className});
        setTimeout(() => setToast({message: '', visible: false}), time);
    };

    return (
        <ToastContext.Provider value={renderToast}>
            {children}
            <ToastComponent toast={toast}/>
        </ToastContext.Provider>
    );
};

export const useToast = () => useContext(ToastContext);

const ToastComponent = ({toast}) => {
    if (!toast.visible) return null;

    // Determine class based on message type or content
    const toastClass = toast?.message?.includes('erfolgreich') ? 'toast-success' : 'toast-error';

    return (
        <div
            className={`toast ${toast.className} fade ${toast.visible ? 'show' : ''} ${toastClass}`}
            style={{
                position: 'fixed',
                bottom: '10%',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '10px 20px',
                borderRadius: '5px',
                zIndex: 1000
            }}
        >
            {toast.message}
        </div>
    );
};
