import {useMemo} from "react";
import {getFormattedTimeNoSeconds, millisToDecimalHours} from "../../utils/time";
import {calculateTotalWorkingTime} from "../../utils/general";

const WorkdayCard = ({workday, onClick}) => {
    const day = useMemo(() => {
        const date = new Date(workday.startTime);
        const dayShort = date.toLocaleDateString('de-AT', {weekday: 'short'});
        const monthDate = date.toLocaleDateString('de-AT', {day: '2-digit', month: '2-digit'});
        return {dayShort, monthDate};
    }, [workday.startTime])

    const totalWorkingTime = useMemo(() => {
        return calculateTotalWorkingTime(workday)
    }, [workday]);

    return (
        <div className={"bg-black-50 rounded-3 d-flex shadow"} onClick={onClick}>
            <div className={"fw-bold text-center me-1 lh-1 bg-primary-50 p-2 rounded-start-3"} style={{minWidth: 60}}>
                <div className={"text-uppercase"} style={{fontSize: "1.5em"}}>
                    {day.dayShort}
                </div>
                <div style={{fontSize: "0.8em"}}>
                    {day.monthDate}
                </div>
            </div>
            <div className={"d-flex justify-content-center align-items-center w-100 fw-bold"}>
                {getFormattedTimeNoSeconds(workday.startTime)} Uhr - {getFormattedTimeNoSeconds(workday.endTime)} Uhr
            </div>
            <div className="vr"></div>
            <div className={"px-2 d-flex text-light-emphasis align-items-center text-nowrap fw-bold"}>
                {millisToDecimalHours(totalWorkingTime)} h
            </div>
        </div>
    );
}

export default WorkdayCard;