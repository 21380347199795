// Unix timestamp to string with date with weekday and time (e.g. Montag, 01. Januar 2024 - 20:15 Uhr)
export const unixToFormattedDateWithDayAndTimeNoSeconds = (timestamp) => {
    const date = new Date(timestamp);
    return getFormattedDateWithDay(date) + ' - ' + getFormattedTimeNoSeconds(date) + ' Uhr'
};

// Unix timestamp to string with date with weekday (e.g. Montag, 01. Januar 2024)
export const unixToFormattedDateWithDay = (timestamp) => {
    const date = new Date(timestamp);
    return getFormattedDateWithDay(date);
};

// Unix timestamp to string with numeric date without year (e.g. 24.12)
export const unixToFormattedDateNoYear = (timestamp) => {
    const date = new Date(timestamp);
    return getFormattedDateNoYear(date);
};

// JS Date object to string (e.g. 01. Januar 2024)
export const getFormattedDate = (date) => {
    const options = {day: 'numeric', month: 'long', year: 'numeric'};
    return date.toLocaleDateString('de-AT', options);
}

// JS Date object to string with short month name (e.g. 01. Jan 2024)
export const getFormattedDateShortMonth = (date) => {
    const options = {day: 'numeric', month: 'short', year: 'numeric'};
    return date.toLocaleDateString('de-AT', options);
}

// JS Date object to string (e.g. Montag, 01. Januar 2024)
export const getFormattedDateWithDay = (date) => {
    const options = {day: 'numeric', month: 'long', year: 'numeric', weekday: 'long'};
    return date.toLocaleDateString('de-AT', options);
}

// JS Date to string with numeric date without year (e.g. 24.12)
export const getFormattedDateNoYear = (date) => {
    const options = {day: '2-digit', month: '2-digit'};
    return date.toLocaleDateString('de-AT', options);
}

// Unix timestamp to time string (e.g. 20:15:00)
export const getFormattedTime = (timestamp) => {
    const date = new Date(timestamp);
    const options = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
    return date.toLocaleTimeString('de-AT', options);
}

// Unix timestamp to time string (e.g. 20:15)
export const getFormattedTimeNoSeconds = (timestamp) => {
    const date = new Date(timestamp);
    const options = {hour: '2-digit', minute: '2-digit'};
    return date.toLocaleTimeString('de-AT', options);
}

// Milliseconds to time duration string (e.g. 08:15:00)
export const millisToTimeString = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    const seconds = totalSeconds - (hours * 3600) - (minutes * 60);

    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = seconds.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}:${secondsString}`;
}

// Seconds to time duration string (e.g. 03:10:25)
export const secondsToTimerString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    const secondsLeft = seconds - hours * 3600 - minutes * 60;

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secondsLeft.toString().padStart(2, '0')}`;
}

// Milliseconds to decimal hours string (e.g. 8.25)
export const millisToDecimalHours = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = totalSeconds / 3600;
    return hours.toFixed(2);
}

// Unix timestamp to string suited for date input
export const unixToInputDate = (timestamp) => {
    if (!timestamp) return '';
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-based in JavaScript
    const day = ("0" + date.getDate()).slice(-2); // Pad with a zero if needed
    return `${year}-${month}-${day}`;
};