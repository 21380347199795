import {useCallback, useEffect, useState} from "react";
import DateNameMark from "../../components/dateNameMark/DateNameMark";
import "./tracking.css";
import WorkdayNotEnded from "./WorkdayNotEnded";
import WorkdayTimeHistory from "../../components/workdayTimeHistory/WorkdayTimeHistory";
import FloatingTextbox from "../../components/floatingTextbox/FloatingTextbox";
import WorkdayEnded from "./WorkdayEnded";
import ModalDarkDanger from "../../components/modal/ModalDarkConfirm";
import {useAuth} from "../../context/AuthContext";
import useAPI from "../../hooks/useAPI";
import {useToast} from "../../context/ToastContext";

const Tracking = () => {
    const {user} = useAuth();
    const renderToast = useToast();

    const {data: currentWDData} = useAPI('workday/current', {autoCall: true})
    const [workday, setWorkday] = useState(null)
    useEffect(() => {
        if (currentWDData != null) setWorkday(currentWDData);
        else setWorkday({});
    }, [currentWDData]);
    const {callAPI: callTasksPut} = useAPI('workday/tasks', {method: "PUT"})
    const {callAPI: callReset} = useAPI('workday/reset-current', {method: "DELETE"})

    const [showResetWorkdayConfirmation, setShowResetWorkdayConfirmation] = useState(false);
    const onResetClick = () => {
        setShowResetWorkdayConfirmation(true);
    };
    const resetWorkday = () => {
        callReset().then(() => {
            setWorkday({});
            setShowResetWorkdayConfirmation(false);
        });
    };

    const debounce = (func, delay) => {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const updateTasks = useCallback(debounce((tasks) => {
        callTasksPut({body: {"tasks": tasks}}).then(() => {
            renderToast("Tätigkeiten gespeichert", "bg-success", 5000);
        });
    }, 1000), []);

    const onTasksChange = (tasks) => {
        setWorkday(prev => ({...prev, tasks}));
        updateTasks(tasks);
    }

    return (
        <div id={"trackingScreen"} className={"text-light h-100 overflow-y-auto overflow-x-hidden max-width-600"}>
            <ModalDarkDanger
                show={showResetWorkdayConfirmation}
                setShow={setShowResetWorkdayConfirmation}
                title={"Arbeitstag zurücksetzen"}
                body={
                    <>
                        <p>Bist du sicher, dass du den Arbeitstag zurücksetzen möchtest?</p>
                        <p className={"fw-bold"}>Alle Daten vom heutigen Arbeitstag gehen verloren!</p>
                    </>
                }
                confirmButtonText={"Arbeitstag zurücksetzen"}
                onConfirm={resetWorkday}
            />

            {!workday ?
                <div className={"d-flex justify-content-center align-items-center h-100"}>
                    <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                : <>
                    <div className={"m-3 d-flex justify-content-between"}>
                        <DateNameMark date={new Date()} firstname={user?.firstname} lastname={user?.lastname}/>
                        <div
                            className={`text-danger trans-opacity ${!workday.startTime && "opacity-0"}`}
                            role={"button"}
                            onClick={onResetClick}
                        >
                            Zurücksetzen
                        </div>
                    </div>

                    {workday.endTime ?
                        <WorkdayEnded workday={workday}/>
                        :
                        <WorkdayNotEnded workday={workday} setWorkday={setWorkday}/>
                    }

                    <div className={`trans-opacity-translateY-1 ${!workday.startTime ? "opacity-0" : "mt-5"}`}
                         style={{
                             transform: `translateY(${workday.startTime ? 0 : 100}px)`
                         }}
                    >
                        <div className={`m-2 mt-5 ${!workday.startTime && "d-none"}`}>
                            <FloatingTextbox
                                label={"Heutige Tätigkeiten"}
                                text={workday.tasks}
                                setText={onTasksChange}
                            />
                        </div>
                        <div className={`m-1 mt-5 mb-4 ${!workday.startTime && "d-none"}`}>
                            <h1 className={"fs-3 mb-3 text-center fw-bold"}>Verlauf des Tages</h1>
                            <WorkdayTimeHistory workday={workday}/>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Tracking;
