import {Link, useLocation} from "react-router-dom";
import "./navigation.css";
import {useAuth} from "../../context/AuthContext";

export const NAVIGATION_HEIGHT = 60;

const Navigation = () => {
    const {isAdmin} = useAuth();

    const location = useLocation();
    const {pathname} = location;
    const splitLocation = pathname.split("/");
    const tab = splitLocation[1];

    // Navbar list items with name, icon and path
    const items = [
        {
            name: "Woche",
            icon: <i className="bi bi-calendar-date"></i>,
            path: "week"
        },
        {
            name: "Arbeitstag",
            icon: <i className="bi bi-stopwatch"></i>,
            path: "tracking"
        },
        {
            name: "Persönlich",
            icon: <i className="bi bi-person"></i>,
            path: "personal"
        }
    ];

    return (
        <div className={"navigationComponent fixed-bottom bg-dark rounded-top-4"} style={{height: NAVIGATION_HEIGHT}}>
            <ul className={"h-100 d-flex flex-row justify-content-around list-unstyled mx-3 my-0"}>
                {items.map((item, index) => {
                    return (
                        <li key={index} className={"d-flex align-items-center"}>
                            <Link className={`text-decoration-none text-light text-center`}
                                  to={`/${item.path}`} style={{minWidth: 80}}>
                                <div>{item.icon}</div>
                                {tab === item.path ? <div className={"navItemText"}>{item.name}</div> : null}
                            </Link>
                        </li>
                    );
                })}
                {isAdmin && (
                    <li className={"d-flex align-items-center"}>
                        <Link className={`text-decoration-none text-light text-center`}
                              to={`/admin`} style={{minWidth: 80}}>
                            <div><i className="bi bi-gear"></i></div>
                            {tab === "admin" ? <div className={"navItemText"}>Admin</div> : null}
                        </Link>
                    </li>

                )}
            </ul>
        </div>
    );
};

export default Navigation;