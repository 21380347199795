import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import useAPI from "../hooks/useAPI";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
    const {callAPI, loading: userLoading} = useAPI('auth');

    const [isAuthenticated, setIsAuthenticated] = useState(undefined);
    const [user, setUser] = useState(null);

    const isAdmin = useMemo(() => user?.authorities?.some((role) => role.authority === 'ADMIN') ?? false, [user]);
    const isEmployee = useMemo(() => user?.authorities?.some((role) => role.authority === 'EMPLOYEE') ?? false, [user]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            callAPI()
                .then((response) => {
                    setIsAuthenticated(true);
                    response.roles = response.authorities;
                    setUser(response);
                })
                .catch(() => {
                    logout();
                });
        } else {
            setIsAuthenticated(false)
        }
    }, []);

    const login = (data) => {
        localStorage.setItem('token', data.jwt);
        setIsAuthenticated(true);
        setUser(data.user);
    };
    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{user, isAuthenticated, isAdmin, isEmployee, userLoading, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
};