import ModalWithChildren from "../../components/modal/ModalWithChildren";
import React, {useEffect, useMemo, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {getFormattedDateWithDay, getFormattedTime} from "../../utils/time";
import ModalDarkDanger from "../../components/modal/ModalDarkConfirm";
import useAPI from "../../hooks/useAPI";
import {useToast} from "../../context/ToastContext";
import {useNavigate} from "react-router-dom";

const EditWorkdayModal = ({show, setShow, handleSubmit, workday, setWorkday}) => {
    const {callAPI: callDelete} = useAPI('workday/', {method: 'DELETE'});

    const [showDelete, setShowDelete] = useState(false);

    const renderToast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        if (!show && workday) {
            setWorkday(null);
        }
    }, [show]);

    const validationMessage = useMemo(() => {
        if (!workday) return "Fehler beim Laden des Arbeitstags";
        if (!workday.startTime) return "Startzeit darf nicht leer sein";
        if (!workday.endTime) return "Endzeit darf nicht leer sein";
        if (workday.startTime >= workday.endTime) return "Endzeit muss nach Startzeit liegen";
        return null;
    }, [workday])

    const handleChange = (e, index, prop) => {
        const id = e.target.id;
        let value = e.target.value;

        if (['startTime', 'endTime'].includes(id) || prop) {
            const date = new Date(workday.startTime ?? workday.endTime).toISOString().split('T')[0];
            value = new Date(`${date}T${value}`).getTime();
        }

        if (prop) {
            const newBreaks = [...workday.breaks];
            newBreaks[index][prop] = value;
            setWorkday({...workday, breaks: newBreaks});
        } else {
            setWorkday({...workday, [id]: value});
        }
    }

    const addBreak = () => {
        const newBreaks = [...workday.breaks, {startTime: '', endTime: ''}];
        setWorkday({...workday, breaks: newBreaks});
    };

    const deleteBreak = (index) => {
        const newBreaks = [...workday.breaks];
        newBreaks.splice(index, 1);
        setWorkday({...workday, breaks: newBreaks});
    };

    const confirmDeleteWorkday = () => {
        callDelete({endpointAddition: workday.id}).then(() => {
            setShowDelete(false);
            setShow(false);
            renderToast("Arbeitstag erfolgreich gelöscht", "bg-warning", 5000);
            navigate('/week');
        }).catch((err) => {
            renderToast(err, "bg-danger");
            setShowDelete(false);
        });
    };

    return (
        <>
            <ModalDarkDanger
                show={showDelete}
                setShow={setShowDelete}
                title={"Arbeitstag löschen"}
                body={
                    <>
                        <p>
                            Bist du sicher, dass du den Arbeitstag vom
                            <span
                                className={"fw-bold mx-1"}>{getFormattedDateWithDay(new Date(workday?.startTime))}</span>
                            löschen möchtest?
                        </p>
                        <p className={"fw-bold"}>Alle Daten von diesem Arbeitstag gehen verloren!</p>
                    </>
                }
                confirmButtonText={"Arbeitstag löschen"}
                onConfirm={confirmDeleteWorkday}
            />
            <ModalWithChildren
                show={show}
                setShow={setShow}
                title={"Arbeitstag bearbeiten"}
                confirmButtonText={"Übernehmen"}
                confirmButtonVariant={"warning"}
                paramsValid={!validationMessage}
                onConfirm={handleSubmit}
                centered={true}
            >
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="startTime">
                        <Form.Label>
                            <span>Startzeit</span>
                            <span className="text-danger ms-1">*</span>
                        </Form.Label>
                        <Form.Control
                            type="time"
                            value={getFormattedTime(workday?.startTime)}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="my-3" controlId="endTime">
                        <Form.Label>
                            <span>Endzeit</span>
                            <span className="text-danger ms-1">*</span>
                        </Form.Label>
                        <Form.Control
                            type="time"
                            value={getFormattedTime(workday?.endTime)}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className={"my-3"} controlId="tasks">
                        <Form.Label>Tätigkeiten</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={workday?.tasks}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className={"my-3"} controlId="breaks">
                        {
                            workday?.breaks?.length > 0 &&
                            <Form.Label>Pausen</Form.Label>
                        }
                        {workday?.breaks?.map((breakItem, index) => (
                            <div key={index} className="d-flex align-items-center mb-2">
                                <Form.Control
                                    type="time"
                                    value={getFormattedTime(breakItem.startTime)}
                                    onChange={(e) => handleChange(e, index, 'startTime')}
                                    required
                                />
                                <span className="mx-2">-</span>
                                <Form.Control
                                    type="time"
                                    value={getFormattedTime(breakItem.endTime)}
                                    onChange={(e) => handleChange(e, index, 'endTime')}
                                    required
                                />
                                <Button
                                    variant="danger"
                                    className="ms-2"
                                    onClick={() => deleteBreak(index)}
                                >
                                    <i className="bi bi-trash"></i>
                                </Button>
                            </div>
                        ))}
                        <Button variant="outline-success" onClick={addBreak}>
                            Pause hinzufügen
                        </Button>
                    </Form.Group>
                    {validationMessage && <Alert variant="danger">{validationMessage}</Alert>}
                </Form>

                <hr className={"my-4"}/>

                <div className={"float-end"}>
                    <Button
                        variant="outline-danger"
                        size={"sm"}
                        onClick={() => setShowDelete(true)}
                    >
                        Arbeitstag Löschen
                    </Button>
                </div>
            </ModalWithChildren>
        </>
    );
}

export default EditWorkdayModal;
