import React, {useEffect, useState} from 'react';
import ModalWithChildren from "../../components/modal/ModalWithChildren";
import {Alert, Button, Form} from 'react-bootstrap';
import {unixToInputDate} from "../../utils/time";

const HolidayRequestModal = ({
                                 show,
                                 setShow,
                                 onConfirm,
                                 onDelete,
                                 editHolidayRequest,
                                 setEditHolidayRequest
                             }) => {
    const [holidayRequest, setHolidayRequest] = useState({});
    const [valid, setValid] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    useEffect(() => {
        // clear the form when the modal is closed
        if (!show) {
            setHolidayRequest({});
            setEditHolidayRequest({});
        }

        // check for edit
        if (show && editHolidayRequest) {
            setHolidayRequest(editHolidayRequest);
        }
    }, [show]);

    const validateFields = () => {
        const {startDate, endDate} = holidayRequest;
        const today = new Date().toISOString().split('T')[0];
        if (startDate && endDate) {
            if (unixToInputDate(startDate) < today) {
                setValid(false);
                setValidationMessage('Startdatum darf nicht in der Vergangenheit liegen.');
            } else if (endDate < startDate) {
                setValid(false);
                setValidationMessage('Enddatum darf nicht vor dem Startdatum liegen.');
            } else {
                setValid(true);
                setValidationMessage('');
            }
        }
    }

    useEffect(() => {
        validateFields();
    }, [holidayRequest]);

    const handleChange = (e) => {
        const id = e.target.id;
        let value = e.target.value;

        // convert date values to unix timestamps
        if (id === 'startDate') {
            value = new Date(value);
            value = value.setHours(0, 0, 0, 0);
        }
        if (id === 'endDate') {
            value = new Date(value)
            value = value.setHours(23, 59, 59, 999);
        }

        setHolidayRequest(prev => ({...prev, [id]: value}));
    };

    const handleSubmit = () => {
        if (valid) {
            onConfirm(holidayRequest);
        }
    };

    return (
        <ModalWithChildren
            show={show}
            setShow={setShow}
            title={editHolidayRequest?.id ? "Urlaubsantrag bearbeiten" : "Urlaubsantrag einreichen"}
            confirmButtonText={editHolidayRequest?.id ? "Urlaubsantrag bearbeiten" : "Urlaubsantrag abschicken"}
            confirmButtonVariant={"success"}
            paramsValid={valid}
            onConfirm={handleSubmit}
        >
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="startDate">
                    <Form.Label>
                        <span>Startdatum (erster Urlaubstag)</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="date"
                        value={unixToInputDate(holidayRequest.startDate)}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group className={"my-3"} controlId="endDate">
                    <Form.Label>
                        <span>Enddatum (letzter Urlaubstag)</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="date"
                        value={unixToInputDate(holidayRequest.endDate)}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group className={"my-3"} controlId="notes">
                    <Form.Label>Zusätzliche Informationen</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={holidayRequest.notes ?? ""}
                        onChange={handleChange}
                    />
                </Form.Group>
                {validationMessage && <Alert variant="danger">{validationMessage}</Alert>}
            </Form>

            {editHolidayRequest?.id && (
                <div className={"mt-4 float-start"}>
                    <Button
                        variant={"danger"}
                        size={"sm"}
                        onClick={onDelete}
                    >
                        Urlaubsantrag löschen
                    </Button>
                </div>
            )}
        </ModalWithChildren>
    );
};

export default HolidayRequestModal;
