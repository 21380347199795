import {getFormattedDate} from "../../utils/time";

const DateNameMark = ({date, firstname, lastname}) => {
    const formattedDate = getFormattedDate(date);
    return (
        <div className={"d-flex gap-2"} style={{fontSize: "1em"}}>
            <div>
                <i style={{fontSize: "0.9em"}} className="bi bi-circle"></i>
            </div>
            <div>
                <div>{formattedDate}</div>
                <div style={{fontSize: "0.8em"}}
                     className={"text-warning text-uppercase d-flex align-items-center gap-2"}>
                    <i style={{fontSize: "0.5em"}}
                       className="bi bi-circle-fill"></i> {firstname + " " + lastname}
                </div>
            </div>
        </div>
    );
}

export default DateNameMark;