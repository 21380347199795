import React, {useEffect, useMemo, useState} from "react";
import {getFormattedDateShortMonth} from "../../../utils/time";
import {Button} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import {DataGrid} from "@mui/x-data-grid";
import {useMediaQuery} from "@mui/material";
import useAPI from "../../../hooks/useAPI";

const columns = [
    {field: 'id', headerName: 'User ID', minWidth: 100, flex: 1},
    {field: 'username', headerName: 'Benutzername', minWidth: 130, flex: 2},
    {field: 'name', headerName: 'Name', minWidth: 130, flex: 2},
    {
        field: "workedHours",
        headerName: "Arbeitsstunden",
        minWidth: 130,
        flex: 2,
        renderCell: (params) => {
            return Math.round(params.row.workedHours * 100) / 100;
        }
    },
];

const PeriodOverview = ({period = "week"}) => {
    const [selectedDate, setSelectedDate] = useState(new Date());

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const timePeriod = useMemo(() => {
        const dayOfWeek = selectedDate.getDay() || 7; // If it's Sunday, set it as 7
        const startOfWeek = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() - dayOfWeek + 1);
        const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6, 23, 59, 59);

        const startOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const endOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0, 23, 59, 59);

        const startOfYear = new Date(selectedDate.getFullYear(), 0, 1);
        const endOfYear = new Date(selectedDate.getFullYear(), 11, 31, 23, 59, 59);

        switch (period) {
            case "week":
                return {start: startOfWeek.getTime(), end: endOfWeek.getTime()};
            case "month":
                return {start: startOfMonth.getTime(), end: endOfMonth.getTime()};
            case "year":
                return {start: startOfYear.getTime(), end: endOfYear.getTime()};
            default:
                return {};
        }
    }, [period, selectedDate]);

    const {data: periodOverviewData, callAPI: callPeriodOverviewFetch} = useAPI(`admin/overview/period`, {
        autoCall: true,
        params: {
            start: timePeriod.start,
            end: timePeriod.end,
        }
    });

    useEffect(() => {
        callPeriodOverviewFetch();
    }, [selectedDate, period]);

    const handlePrev = () => {
        const newDate = new Date(selectedDate);
        switch (period) {
            case "week":
                newDate.setDate(selectedDate.getDate() - 7);
                break;
            case "month":
                newDate.setMonth(selectedDate.getMonth() - 1);
                break;
            case "year":
                newDate.setFullYear(selectedDate.getFullYear() - 1);
                break;
            default:
                break;
        }
        setSelectedDate(newDate);
    };

    const handleNext = () => {
        const newDate = new Date(selectedDate);
        switch (period) {
            case "week":
                newDate.setDate(selectedDate.getDate() + 7);
                break;
            case "month":
                newDate.setMonth(selectedDate.getMonth() + 1);
                break;
            case "year":
                newDate.setFullYear(selectedDate.getFullYear() + 1);
                break;
            default:
                break;
        }
        setSelectedDate(newDate);
    };

    const isCurrent = () => {
        const now = new Date();
        switch (period) {
            case "week":
                return timePeriod.end >= now.getTime();
            case "month":
                return timePeriod.end >= now.getTime();
            case "year":
                return timePeriod.end >= now.getTime();
            default:
                return false;
        }
    };

    const rows = useMemo(() => {
        if (!periodOverviewData) {
            return [];
        }

        return periodOverviewData.userData.map((data, index) => {
            return {
                id: data.user.userId,
                username: data.user.username,
                name: `${data.user.firstname} ${data.user.lastname}`,
                workedHours: data.workedMillis / 1000 / 60 / 60,
            };
        });
    }, [periodOverviewData]);

    return (
        <div className={"m-2 mx-lg-5"}>
            <div className="d-flex justify-content-center align-items-center gap-2 my-3 mb-5 fs-5">
                <Button
                    variant={"light"}
                    size={"lg"}
                    onClick={handlePrev}
                    className={"text-secondary"}
                >
                    <i className="bi bi-caret-left-fill"></i>
                </Button>
                <div className={"bg-light border border-1 border-light p-2 rounded text-secondary"}>
                    {getFormattedDateShortMonth(new Date(timePeriod.start))} – {getFormattedDateShortMonth(new Date(timePeriod.end))}
                </div>
                <Button
                    variant={"light"}
                    size={"lg"}
                    onClick={handleNext}
                    disabled={isCurrent()}
                    className={"text-secondary"}
                >
                    <i className="bi bi-caret-right-fill"></i>
                </Button>
            </div>

            <div className={"my-5 max-width-800 mx-auto"}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 25},
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiDataGrid-sortIcon': {
                                display: isSmallScreen ? 'none' : 'block',
                            },
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default PeriodOverview;
