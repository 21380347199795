import ModalWithChildren from "../../components/modal/ModalWithChildren";
import React, {useMemo, useState} from "react";
import {Alert, Button, Form} from "react-bootstrap";
import {getFormattedTime} from "../../utils/time";
import {useToast} from "../../context/ToastContext";
import useAPI from "../../hooks/useAPI";

const AddWorkdayModal = ({show, setShow, onCreated}) => {
    const {callAPI: callAdd} = useAPI('workday', {method: 'POST'});

    const [workday, setWorkday] = useState({
        startTime: '',
        endTime: '',
        tasks: '',
        breaks: []
    });

    const [selectedDate, setSelectedDate] = useState('');
    const [touchedFields, setTouchedFields] = useState({
        date: false,
        startTime: false,
        endTime: false,
        tasks: false,
        breaks: []
    });

    const renderToast = useToast();

    const validationMessage = useMemo(() => {
        if (!selectedDate) return "Datum darf nicht leer sein";
        if (new Date(selectedDate) > new Date()) return "Datum darf nicht in der Zukunft liegen";
        if (!workday.startTime) return "Startzeit darf nicht leer sein";
        if (!workday.endTime) return "Endzeit darf nicht leer sein";
        if (workday.startTime >= workday.endTime) return "Endzeit muss nach Startzeit liegen";
        return null;
    }, [selectedDate, workday]);

    const handleChange = (e, index, prop) => {
        const id = e.target.id;
        let value = e.target.value;

        if (['startTime', 'endTime'].includes(id) || prop) {
            value = new Date(`${selectedDate}T${value}`).getTime();
        }

        if (prop) {
            const newBreaks = [...workday.breaks];
            newBreaks[index][prop] = value;
            setWorkday({...workday, breaks: newBreaks});
            const newBreaksTouched = [...touchedFields.breaks];
            newBreaksTouched[index] = {...newBreaksTouched[index], [prop]: true};
            setTouchedFields({...touchedFields, breaks: newBreaksTouched});
        } else if (id === 'date') {
            setSelectedDate(value);
            setTouchedFields({...touchedFields, date: true});
        } else {
            setWorkday({...workday, [id]: value});
            setTouchedFields({...touchedFields, [id]: true});
        }
    };

    const addBreak = () => {
        const newBreaks = [...workday.breaks, {startTime: '', endTime: ''}];
        setWorkday({...workday, breaks: newBreaks});
        const newBreaksTouched = [...touchedFields.breaks, {startTime: false, endTime: false}];
        setTouchedFields({...touchedFields, breaks: newBreaksTouched});
    };

    const deleteBreak = (index) => {
        const newBreaks = [...workday.breaks];
        newBreaks.splice(index, 1);
        setWorkday({...workday, breaks: newBreaks});
        const newBreaksTouched = [...touchedFields.breaks];
        newBreaksTouched.splice(index, 1);
        setTouchedFields({...touchedFields, breaks: newBreaksTouched});
    };

    const getBreakValidationMessage = (breakItem) => {
        if (!breakItem.startTime) return "Startzeit der Pause darf nicht leer sein";
        if (!breakItem.endTime) return "Endzeit der Pause darf nicht leer sein";
        if (breakItem.startTime >= breakItem.endTime) return "Endzeit der Pause muss nach Startzeit liegen";
        return null;
    };

    const handleSubmit = () => {
        callAdd({body: workday}).then(() => {
            setShow(false);
            renderToast("Arbeitstag erfolgreich hinzugefügt", "bg-success", 5000);
            onCreated();
        }).catch((err) => {
            renderToast(err.response.data, "bg-danger", 5000);
        });
    }

    return (
        <ModalWithChildren
            show={show}
            setShow={setShow}
            title={"Arbeitstag hinzufügen"}
            confirmButtonText={"Hinzufügen"}
            confirmButtonVariant={"success"}
            paramsValid={!validationMessage}
            onConfirm={handleSubmit}
            centered={true}
        >
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="date">
                    <Form.Label>
                        <span>Datum</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="date"
                        max={new Date().toISOString().split('T')[0]}
                        value={selectedDate}
                        onChange={handleChange}
                        required
                    />
                    {touchedFields.date && validationMessage === "Datum darf nicht leer sein" &&
                        <Alert className={"my-2"} variant="danger">{validationMessage}</Alert>}
                    {touchedFields.date && validationMessage === "Datum darf nicht in der Zukunft liegen" &&
                        <Alert className={"my-2"} variant="danger">{validationMessage}</Alert>}
                </Form.Group>
                <Form.Group controlId="startTime" className="my-3">
                    <Form.Label>
                        <span>Startzeit</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="time"
                        value={getFormattedTime(workday?.startTime)}
                        onChange={handleChange}
                        required
                    />
                    {touchedFields.startTime && validationMessage === "Startzeit darf nicht leer sein" &&
                        <Alert className={"my-2"} variant="danger">{validationMessage}</Alert>}
                </Form.Group>
                <Form.Group className="my-3" controlId="endTime">
                    <Form.Label>
                        <span>Endzeit</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="time"
                        value={getFormattedTime(workday?.endTime)}
                        onChange={handleChange}
                        required
                    />
                    {touchedFields.endTime && (validationMessage === "Endzeit darf nicht leer sein" || validationMessage === "Endzeit muss nach Startzeit liegen") &&
                        <Alert className={"my-2"} variant="danger">{validationMessage}</Alert>}
                </Form.Group>
                <Form.Group className={"my-3"} controlId="tasks">
                    <Form.Label>Tätigkeiten</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        value={workday?.tasks}
                        onChange={handleChange}
                    />
                </Form.Group>
                <Form.Group className={"my-3"} controlId="breaks">
                    {workday?.breaks?.length > 0 && <Form.Label>Pausen</Form.Label>}
                    {workday?.breaks?.map((breakItem, index) => (
                        <div key={index} className="d-flex align-items-center mb-2">
                            <Form.Control
                                type="time"
                                value={getFormattedTime(breakItem.startTime)}
                                onChange={(e) => handleChange(e, index, 'startTime')}
                                required
                            />
                            <span className="mx-2">-</span>
                            <Form.Control
                                type="time"
                                value={getFormattedTime(breakItem.endTime)}
                                onChange={(e) => handleChange(e, index, 'endTime')}
                                required
                            />
                            <Button
                                variant="danger"
                                className="ms-2"
                                onClick={() => deleteBreak(index)}
                            >
                                <i className="bi bi-trash"></i>
                            </Button>
                            {touchedFields.breaks[index]?.startTime && getBreakValidationMessage(breakItem) === "Startzeit der Pause darf nicht leer sein" &&
                                <Alert className={"my-2"}
                                       variant="danger">{getBreakValidationMessage(breakItem)}</Alert>}
                            {touchedFields.breaks[index]?.endTime && (getBreakValidationMessage(breakItem) === "Endzeit der Pause darf nicht leer sein" || getBreakValidationMessage(breakItem) === "Endzeit der Pause muss nach Startzeit liegen") &&
                                <Alert className={"my-2"}
                                       variant="danger">{getBreakValidationMessage(breakItem)}</Alert>}
                        </div>
                    ))}
                    <Button variant="outline-success" onClick={addBreak}>
                        Pause hinzufügen
                    </Button>
                </Form.Group>
            </Form>
        </ModalWithChildren>
    );
}

export default AddWorkdayModal;
