import {useEffect, useMemo, useState} from "react";
import "./track.css";
import {secondsToTimerString} from "../../utils/time";
import anime from "animejs";
import useAPI from "../../hooks/useAPI";
import {calculateWorkingTime} from "../../utils/general";

const Track = ({workday, setWorkday}) => {
    const {callAPI: callWorkdayStart} = useAPI('workday/start', {method: "POST"})

    const [timer, setTimer] = useState({seconds: 0, running: false});

    const isInWork = useMemo(() => {
        if (!workday.startTime) return false;
        return !workday.endTime;
    }, [workday])

    const isInBreak = useMemo(() => {
        if (!workday.breaks?.length) return false;
        return !workday.breaks[workday.breaks.length - 1].endTime;
    }, [workday]);

    const handleTrackClick = () => {
        // if workday is not started yet, start by setting the startTime for the workday data
        if (!isInWork) {
            const starTime = new Date().getTime();

            setWorkday(prev => ({...prev, startTime: starTime}));

            // call API for starting workday
            callWorkdayStart({body: {"startTime": starTime}}).then(data => {
                setWorkday(data);
            });
        }
    }

    const shadowPulseAnimation = {
        targets: '.circle',
        boxShadow: [
            "0 0 0 0 rgba(255, 255, 255, 0.5)",
            "0 0 0 40px rgba(255, 255, 255, 0)"
        ],
        easing: 'easeInOutSine',
        duration: 3000,
        loop: true
    };

    const secondsPulseAnimation = {
        targets: '.circle',
        backgroundColor: [
            'rgba(255, 255, 255, 0)',
            'rgba(255, 255, 255, 0.03)'
        ],
        easing: 'easeInOutSine',
        duration: 500,
        loop: true,
        direction: 'alternate'
    };

    useEffect(() => {
        anime(shadowPulseAnimation);
    }, []);

    useEffect(() => {
        let interval = null;
        const circle = document.querySelector('.circle');
        if (timer.running) {
            anime.remove('.circle');
            circle.style.transition = 'box-shadow 0.5s ease-in-out';
            circle.style.boxShadow = '0 0 0 0 rgba(255, 255, 255, 0)';
            setTimeout(() => {
                anime(secondsPulseAnimation);
            }, 750);

            interval = setInterval(() => {
                setTimer(prevTimer => ({
                    ...prevTimer,
                    seconds: prevTimer.seconds + 1
                }));
            }, 1000);
        } else if (!timer.running && timer.seconds !== 0) {
            clearInterval(interval);

            // switch animation
            anime.remove('.circle');
            circle.style.transition = 'background-color 0.5s ease-in-out';
            circle.style.backgroundColor = 'rgba(255, 255, 255, 0)';
            anime(shadowPulseAnimation);
        }
        return () => clearInterval(interval);
    }, [timer.running]);

    useEffect(() => {
        if (isInWork) {
            if (isInBreak) {
                const seconds = Math.round(calculateWorkingTime(workday) / 1000);
                setTimer(prev => ({...prev, seconds: seconds, running: false}));
            } else {
                if (!timer.running) {
                    const seconds = Math.round(calculateWorkingTime(workday) / 1000);
                    setTimer(prev => ({...prev, running: true, seconds: seconds}));
                }
            }
        } else {
            // if startTime is set, but not in work (endTime is set), stop the timer (workday ended)
            // if startTime is not set (e.g. workday reset), stop the timer
            if (workday.startTime) {
                if (timer.running) {
                    setTimer(prev => ({...prev, running: false}));
                }
            } else {
                setTimer(prev => ({seconds: 0, running: false}));
            }
        }
    }, [workday]);

    const inWorkComponent = () => {
        return (
            <div className={"text-center lead"}>
                <div>Heutige Arbeitszeit</div>
                <div className={"fs-3 fw-bold"}>{secondsToTimerString(timer.seconds)}</div>
                <div
                    className={`${!isInBreak && "opacity-0"} lead position-absolute fixed-bottom m-3 fw-bold trans-opacity`}>Pausiert
                </div>
            </div>
        );
    }

    const notInWorkComponent = () => {
        return (
            <div className={"lead fs-3"}>
                Arbeitstag beginnen
            </div>
        );
    }

    return (
        <div
            className={`circle rounded-circle d-flex align-items-center justify-content-center`}
            role={"button"}
            onClick={handleTrackClick}
        >
            {
                isInWork ? inWorkComponent() : notInWorkComponent()
            }
        </div>
    );
}

export default Track;