import {Button, Modal} from "react-bootstrap";

const ModalDarkDanger = (
    {
        show,
        setShow,
        title,
        body,
        confirmButtonText,
        onConfirm
    }) => {
    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            data-bs-theme={"dark"}
            className={"text-light bg-black-75"}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Abbrechen
                </Button>
                <Button variant="danger" onClick={() => {
                    setShow(false);
                    onConfirm();
                }}>
                    {confirmButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalDarkDanger;