import React, {useEffect, useState} from 'react';
import ModalWithChildren from "../../components/modal/ModalWithChildren";
import {Alert, Button, Form} from 'react-bootstrap';
import {unixToInputDate} from "../../utils/time";

const TimeCompRequestModal = ({
                                  show,
                                  setShow,
                                  onConfirm,
                                  onDelete,
                                  editTimeCompRequest,
                                  setEditTimeCompRequest
                              }) => {
    const [timeCompRequest, setTimeCompRequest] = useState({});
    const [valid, setValid] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    useEffect(() => {
        // clear the form when the modal is closed
        if (!show) {
            setTimeCompRequest({});
            setEditTimeCompRequest({});
        }

        // check for edit
        if (show && editTimeCompRequest) {
            setTimeCompRequest(editTimeCompRequest);
        }
    }, [show]);

    const validateFields = () => {
        const {date, hours} = timeCompRequest;
        const today = new Date().toISOString().split('T')[0];

        if (date && hours) {
            if (unixToInputDate(date) < today) {
                setValid(false);
                setValidationMessage('Startdatum darf nicht in der Vergangenheit liegen.');
            } else if (hours <= 0) {
                setValid(false);
                setValidationMessage('Stundenanzahl muss größer als 0 sein.');
            } else {
                setValid(true);
                setValidationMessage('');
            }
        } else {
            setValid(false);
        }
    }

    useEffect(() => {
        validateFields();
    }, [timeCompRequest]);

    const handleChange = (e) => {
        const id = e.target.id;
        let value = e.target.value;

        // convert date values to unix timestamps
        if (id === 'date') {
            value = new Date(value);
            value = value.setHours(0, 0, 0, 0);
        }

        setTimeCompRequest(prev => ({...prev, [id]: value}));
    };

    const handleSubmit = () => {
        if (valid) {
            onConfirm(timeCompRequest);
        }
    };

    return (
        <ModalWithChildren
            show={show}
            setShow={setShow}
            title={editTimeCompRequest?.id ? "Antrag bearbeiten" : "Zeitausgleich beantragen"}
            confirmButtonText={editTimeCompRequest?.id ? "Antrag bearbeiten" : "Zeitausgleich beantragen"}
            confirmButtonVariant={"success"}
            paramsValid={valid}
            onConfirm={handleSubmit}
        >
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="date">
                    <Form.Label>
                        <span>Datum</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="date"
                        min={unixToInputDate(new Date().getTime())}
                        value={unixToInputDate(timeCompRequest.date)}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group className={"my-3"} controlId="hours">
                    <Form.Label>
                        <span>Dauer (in Stunden)</span>
                        <span className="text-danger ms-1">*</span>
                    </Form.Label>
                    <Form.Control
                        type="number"
                        min={0.5}
                        step={0.5}
                        value={timeCompRequest.hours}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group className={"my-3"} controlId="notes">
                    <Form.Label>Zusätzliche Informationen</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={timeCompRequest.notes ?? ""}
                        onChange={handleChange}
                    />
                </Form.Group>
                {validationMessage && <Alert variant="danger">{validationMessage}</Alert>}
            </Form>

            {editTimeCompRequest?.id && (
                <div className={"mt-4 float-start"}>
                    <Button
                        variant={"danger"}
                        size={"sm"}
                        onClick={onDelete}
                    >
                        Antrag löschen
                    </Button>
                </div>
            )}
        </ModalWithChildren>
    );
};

export default TimeCompRequestModal;
