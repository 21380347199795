import React, {useEffect} from 'react';

const FloatingTextbox = ({text, setText, label}) => {
    const handleOnChange = (event) => {
        setText(event.target.value);
    };

    const handleTextBoxHeight = () => {
        const textarea = document.getElementById("floatingTextarea");
        textarea.style.height = "0";
        const height = textarea.scrollHeight + 2;
        textarea.style.height = height + 'px';
    }

    useEffect(() => {
        handleTextBoxHeight();
    }, []);

    useEffect(() => {
        handleTextBoxHeight();
    }, [text]);

    return (
        <div className="form-floating text-dark">
            <textarea
                className="form-control form-floating text-white border-0 rounded-4 shadow"
                style={{
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                }}
                placeholder="Tätigkeiten hier schreiben"
                id="floatingTextarea"
                onChange={handleOnChange}
                value={text}
            ></textarea>
            <label htmlFor="floatingTextarea" className={"text-white floatingTextboxLabel"}>{label}</label>
        </div>
    );
};

export default FloatingTextbox;
