import useAPI from "../../../hooks/useAPI";
import {Button, Spinner} from "react-bootstrap";
import {useEffect, useMemo, useState} from "react";
import EmployeesStateAmountBar from "../../../components/employeeStateBar/EmployeesStateAmountBar";
import {DataGrid} from '@mui/x-data-grid';
import EmployeeDayHistoryBar from "../../../components/employeeStateBar/EmployeeDayHistoryBar";
import WorkdayDrawer from "../../../components/workdayDrawer/WorkdayDrawer";
import {useMediaQuery} from "@mui/material";

const columns = [
    {field: 'id', headerName: 'User ID', flex: 1, minWidth: 100},
    {field: 'username', headerName: 'Benutzername', flex: 2, minWidth: 100},
    {field: 'name', headerName: 'Name', flex: 2, minWidth: 120},
    {
        field: 'state',
        headerName: 'Status',
        flex: 2,
        minWidth: 125,
        renderCell: (params) => {
            let className = '';
            switch (params.value) {
                case states.working:
                    className = "text-bg-primary p-1 rounded bg-opacity-75";
                    break;
                case states.holiday:
                    className = "text-bg-success p-1 rounded bg-opacity-75";
                    break;
                case states.sickleave:
                    className = "text-bg-warning p-1 rounded bg-opacity-75";
                    break;
                case states.notLogged:
                    className = "text-bg-secondary p-1 rounded bg-opacity-75";
                    break;
                default:
                    className = '';
            }
            return <span className={className}>{params.value}</span>;
        }
    },
    {
        field: "dayHistory",
        headerName: "Tagesverlauf",
        flex: 5,
        minWidth: 160,
        sortable: false,
        renderCell: (params) => {
            if (params.row.state === states.working) {
                return <div className={"d-flex h-100 align-items-center"}>
                    <EmployeeDayHistoryBar workday={params.row.workday}/>
                </div>;
            } else {
                return "-"
            }
        }
    },
];

const states = {
    working: "bei der Arbeit",
    holiday: "im Urlaub",
    sickleave: "im Krankenstand",
    notLogged: "nicht gemeldet",
};

const DayOverview = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const [selectedDate, setSelectedDate] = useState(new Date());
    useEffect(() => {
        callDayOverviewFetch();
    }, [selectedDate]);

    const {data: dayOverviewData, callAPI: callDayOverviewFetch} = useAPI(`admin/overview/day`, {
        autoCall: true,
        params: {
            date: selectedDate.getTime()
        }
    });

    const [drawerData, setDrawerData] = useState(null);

    const [overviewData, setOverviewData] = useState(null);
    useEffect(() => {
        if (dayOverviewData) {
            setOverviewData(dayOverviewData);
        }
    }, [dayOverviewData]);

    const rows = useMemo(() => {
        if (!overviewData) {
            return [];
        }

        return overviewData.userData.map((data, index) => {
            let state = states.notLogged;
            if (data.holiday) state = states.holiday;
            if (data.sickleave) state = states.sickleave;
            if (data.workday) state = states.working;

            return {
                id: data.user.userId,
                username: data.user.username,
                name: `${data.user.firstname} ${data.user.lastname}`,
                state: state,
                workday: data.workday,
            };
        });
    }, [overviewData]);

    const iOS =
        typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (!overviewData) {
        return <div className={"d-flex justify-content-center align-items-center h-100 w-100"}><Spinner/></div>;
    }

    const onRowClick = (params) => {
        if (params.row.state === states.working) setDrawerData(params.row);
    };

    const handlePrevDay = () => {
        setSelectedDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() - 1);
            return newDate;
        });
    };

    const handleNextDay = () => {
        setSelectedDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() + 1);
            return newDate;
        });
    };

    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const handleDateChange = (event) => {
        setSelectedDate(new Date(event.target.value));
    };

    const offsetMinues = new Date().getTimezoneOffset();
    const maxDate = new Date();
    maxDate.setMinutes(maxDate.getMinutes() - offsetMinues);

    return (
        <div className={"m-2 mx-lg-5"}>
            <div className="d-flex justify-content-center align-items-center my-3 mb-5 fs-5">
                <Button
                    variant={"light"}
                    size={"lg"}
                    onClick={handlePrevDay}
                    className={"text-secondary"}
                >
                    <i className="bi bi-caret-left-fill"></i>
                </Button>
                <input
                    type="date"
                    value={selectedDate.toISOString().split('T')[0]}
                    onChange={handleDateChange}
                    max={maxDate.toISOString().split('T')[0]}
                    required={true}
                    className={"mx-2 px-3 py-4 rounded border-0 bg-light text-secondary"}
                    style={{height: "40px"}}
                />
                <Button
                    variant={"light"}
                    size={"lg"}
                    onClick={handleNextDay}
                    disabled={isToday(selectedDate)}
                    className={"text-secondary"}
                >
                    <i className="bi bi-caret-right-fill"></i>
                </Button>
            </div>

            <EmployeesStateAmountBar overviewData={overviewData}/>

            <div className={"my-5"}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 25},
                        },
                    }}
                    onRowClick={onRowClick}
                    sx={{
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            display: 'flex',
                            alignItems: 'center',
                            '& .MuiDataGrid-sortIcon': {
                                display: isSmallScreen ? 'none' : 'block',
                            },
                        },
                    }}
                />
            </div>

            <WorkdayDrawer
                drawerData={drawerData}
                onClose={() => setDrawerData(null)}
                iOS={iOS}
            />
        </div>
    );
};

export default DayOverview;
