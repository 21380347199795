import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useState} from "react";
import DayOverview from "./DayOverview";
import PeriodOverview from "./PeriodOverview";

const Overview = () => {
    const [timeSelection, setTimeSelection] = useState("day");

    return (
        <>
            <div className={"d-flex justify-content-center my-4"}>
                <ToggleButtonGroup
                    value={timeSelection}
                    exclusive
                    onChange={(_, newTimeSelection) => {
                        setTimeSelection(newTimeSelection);
                    }}
                >
                    <ToggleButton value={"day"} style={{width: "90px"}}>
                        Tag
                    </ToggleButton>
                    <ToggleButton value={"week"} style={{width: "90px"}}>
                        Woche
                    </ToggleButton>
                    <ToggleButton value={"month"} style={{width: "90px"}}>
                        Monat
                    </ToggleButton>
                    <ToggleButton value={"year"} style={{width: "90px"}}>
                        Jahr
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>

            <main>
                {
                    timeSelection === "day" ? <DayOverview/> : <PeriodOverview period={timeSelection}/>
                }
            </main>
        </>
    )
}

export default Overview;