import React, {useState} from 'react';
import EditEmployeeModal from "./EditEmployeeModal";
import {isUserAdmin} from "../../utils/general";
import useAPI from "../../hooks/useAPI";

const EmployeeList = ({employees, setEmployees}) => {
    const {callAPI: callPut} = useAPI(
        'admin/usermanagement',
        {method: "PUT"}
    );
    const {callAPI: callDelete} = useAPI(
        'admin/usermanagement',
        {method: "DELETE"}
    );

    const [editEmployee, setEditEmployee] = useState({});
    const [showEditModal, setShowEditModal] = useState(false);

    const onEditEmployeeClick = (employee) => {
        setEditEmployee(employee);
        setShowEditModal(true);
    };

    const handleSubmitEdit = () => {
        callPut({body: editEmployee}).then(() => {
            setEmployees(prev => prev.map(employee => employee.id === editEmployee.id ? editEmployee : employee));
            setShowEditModal(false);
            setEditEmployee({});
        }).catch((error) => {
            alert("Ein Fehler ist aufgetreten: " + error);
        });
    }

    const handleDelete = () => {
        callDelete({
            endpointAddition: `/${editEmployee.id}`,
            body: editEmployee,
        }).then(() => {
            setEmployees(prev => prev.filter(employee => employee.id !== editEmployee.id));
            setEditEmployee({});
        });
    };

    return (
        <>
            <EditEmployeeModal
                employees={employees}
                editEmployee={editEmployee}
                setEditEmployee={setEditEmployee}
                show={showEditModal}
                setShow={setShowEditModal}
                handleSubmit={handleSubmitEdit}
                handleDelete={handleDelete}
            />
            <div className="card shadow-lg">
                <div className="card-body">
                    <h5 className="card-title">Alle Mitarbeiter</h5>
                    <h6 className="mb-2 text-muted card-subtitle">Hier kannst du die Mitarbeiter verwalten</h6>
                    <div className="table-responsive">
                        <table className="no-wrap mt-3 align-middle table table-borderless">
                            <thead>
                            <tr>
                                <th>Mitarbeiter</th>
                                <th className="text-end">Verwalten</th>
                            </tr>
                            </thead>
                            <tbody>
                            {employees.map((employee) => (
                                <tr className="border-top" key={employee.id}>
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                            <div
                                                className="bg-warning-subtle rounded-circle d-flex justify-content-center align-items-center"
                                                style={{width: "3em", height: "3em"}}
                                            >
                                                <i className={`bi ${isUserAdmin(employee) ? "bi-person-gear" : "bi-person"} text-secondary`}
                                                   style={{fontSize: "1.75em"}}></i>
                                            </div>
                                            <div className="ms-3">
                                                <h6 className="mb-0">
                                                    {employee.firstname} {employee.lastname}
                                                </h6>
                                                <span className="text-muted">{employee.username}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        <div className="btn hover-bg-grey"
                                             onClick={() => onEditEmployeeClick(employee)}
                                             role="button">
                                            <i className="bi bi-pencil-square"></i>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeList;
