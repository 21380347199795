import {useMemo, useState} from "react";
import ModalDarkDanger from "../../components/modal/ModalDarkConfirm";
import useAPI from "../../hooks/useAPI";
import {useToast} from "../../context/ToastContext";

const PauseAndStopButtons = ({workday, setWorkday}) => {
    const {callAPI: callWorkdayEnd} = useAPI('workday/end', {method: "PATCH"})
    const {callAPI: callBreakStart} = useAPI('workday/start-break', {method: "POST"})
    const {callAPI: callBreakEnd} = useAPI('workday/end-break', {method: "PATCH"})

    const renderToast = useToast();

    const isInBreak = useMemo(() => {
        if (!workday.breaks?.length) return false;
        return !workday.breaks[workday.breaks.length - 1].endTime;
    }, [workday]);

    const breakClick = () => {
        const time = new Date().getTime();
        if (isInBreak) {
            const breaks = workday.breaks;
            breaks[breaks.length - 1].endTime = time;
            setWorkday(prev => ({...prev, breaks: breaks}));
            callBreakEnd({body: {endTime: time}}).then(data => {
                setWorkday(data);
            });
        } else {
            const breaks = workday.breaks ?? [];
            breaks.push({startTime: new Date().getTime()});
            callBreakStart({body: {startTime: time}}).then(data => {
                setWorkday(data);
            });
        }
    };

    const [showStopWorkdayConfirmation, setShowStopWorkdayConfirmation] = useState(false);

    const endWorkday = () => {
        const newWorkdayState = {...workday};
        // if (isInBreak) {
        //     const breaks = workday.breaks;
        //     breaks[breaks.length - 1].endTime = new Date().getTime();
        //     newWorkdayState.breaks = breaks;
        // }
        newWorkdayState.endTime = new Date().getTime();

        callWorkdayEnd({
                body: {
                    "endTime": new Date(newWorkdayState.endTime).getTime()
                }
            }
        ).then(data => {
            setWorkday(data);
        }).catch(err => {
            console.log(err)
            renderToast(err.response.data, "bg-warning", 6000)
        });
    }

    return (
        <>
            <ModalDarkDanger
                show={showStopWorkdayConfirmation}
                setShow={setShowStopWorkdayConfirmation}
                title={"Arbeitstag beenden"}
                body={"Bist du sicher, dass du den Arbeitstag beenden möchtest?"}
                confirmButtonText={"Arbeitstag beenden"}
                onConfirm={endWorkday}
            />

            <div className={"d-flex gap-2 text-center"}>
                <div className={"w-100"}>
                    <button
                        className={"btn bg-white-02 text-white rounded-circle shadow"}
                        style={{width: "75px", height: "75px"}}
                        onClick={breakClick}
                    >
                        {isInBreak ?
                            <i className="bi bi-play-fill fs-1"/>
                            :
                            <i className="bi bi-pause-fill fs-1"/>
                        }
                    </button>
                    <div className={"text-light fw-light mt-2"}>
                        Pause {isInBreak ? "beenden" : "beginnen"}
                    </div>
                </div>
                <div className={"w-100"}>
                    <button
                        className={"btn bg-white-02 text-white rounded-circle shadow"}
                        style={{width: "75px", height: "75px"}}
                        onClick={() => setShowStopWorkdayConfirmation(true)}
                    >
                        <i className="bi bi-stop-fill fs-1"></i>
                    </button>
                    <div className={"text-light fw-light mt-2"}>Arbeitstag beenden</div>
                </div>
            </div>
        </>
    );
};

export default PauseAndStopButtons;