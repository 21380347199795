import {useAuth} from "../../context/AuthContext";
import useAPI from "../../hooks/useAPI";
import {useState} from "react";
import {Navigate} from "react-router-dom";
import {Image} from "react-bootstrap";

/**
 * Login page component
 */
const Login = () => {
    const {isAuthenticated, login} = useAuth();
    const {callAPI, error: apiErr} = useAPI('auth/login', {method: 'POST'});

    // states for form fields
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    // redirect to home if user is authenticated
    if (isAuthenticated) {
        return <Navigate to="/"/>;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await callAPI({
                body: {username, password}
            });
            login(data);
        } catch (error) {
            console.log("Login failed:", apiErr);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="h-100 d-flex align-items-center justify-content-center bg-light p-2">
            <div className="container bg-white rounded-5 p-3 py-5 p-md-5" style={{maxWidth: 900}}>
                <h4 className="text-center mb-5 bg-primary-50 text-white rounded-2 p-3 shadow">
                    <span className={"fw-bold"}>Strenn</span> – Zeiterfassungssystem
                </h4>
                <div className="row">
                    <div className="col-md-6">
                        <div className={"d-flex gap-4 align-items-center mb-5"}>
                            <Image src="/logo192.png" alt="Strenn Logo" width={75} fluid/>
                            <div>
                                <h3>Anmelden</h3>
                                <p className="text-muted mb-0">
                                    Verwalte deine Arbeitszeiten
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Benutzername</label>
                                <input
                                    type="text"
                                    className={`form-control ${apiErr ? 'is-invalid' : ''}`}
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <label htmlFor="password" className="form-label">Passwort</label>
                                <div className="input-group">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        className={`form-control ${apiErr ? 'is-invalid' : ''}`}
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={toggleShowPassword}
                                    >
                                        <i className={showPassword ? "bi bi-eye" : "bi bi-eye-slash"}></i>
                                    </button>
                                    {apiErr && <div className="invalid-feedback">
                                        Bitte überprüfe deine Eingaben
                                    </div>}
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary float-end">
                                Anmelden
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
