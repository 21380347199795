import {Dropdown, Image} from "react-bootstrap";
import "./personal.css";
import SickLeaveModal from "./SickLeaveModal";
import React, {useState} from "react";
import HolidayRequestModal from "./HolidayRequestModal";
import {useAuth} from "../../context/AuthContext";
import {userTypeString} from "../../utils/general";
import {useNavigate} from "react-router-dom";
import useAPI from "../../hooks/useAPI";
import {useToast} from "../../context/ToastContext";
import CompactSickLeaveCard from "../../components/employeeRequest/CompactSickLeaveCard";
import CompactHolidayCard from "../../components/employeeRequest/CompactHolidayCard";
import TimeCompRequestModal from "./TimeCompRequestModal";
import CompactTimeCompCard from "../../components/employeeRequest/CompactTimeCompCard";
import {millisToDecimalHours} from "../../utils/time";

const Personal = () => {
    const {callAPI: getSickleaves, data: sickleaves} = useAPI('sickleave', {autoCall: true});
    const {callAPI: callSickleaveAPI} = useAPI('sickleave', {method: "POST"});
    const {callAPI: callSickleaveEdit} = useAPI('sickleave', {method: "PUT"});
    const {callAPI: callSickleaveDelete} = useAPI('sickleave', {method: "DELETE"});
    const {callAPI: getHolidays, data: holidays} = useAPI('holiday', {autoCall: true});
    const {callAPI: callHolidayAPI} = useAPI('holiday', {method: "POST"});
    const {callAPI: callHolidayEdit} = useAPI('holiday', {method: "PUT"});
    const {callAPI: callHolidayDelete} = useAPI('holiday', {method: "DELETE"});
    const {callAPI: getTimeComps, data: timeComps} = useAPI('timecomp', {autoCall: true});
    const {callAPI: callTimeCompAPI} = useAPI('timecomp', {method: "POST"});
    const {callAPI: callTimeCompEdit} = useAPI('timecomp', {method: "PUT"});
    const {callAPI: callTimeCompDelete} = useAPI('timecomp', {method: "DELETE"});
    const {callAPI: getTimeCompBalance, data: timeCompBalance} = useAPI("timecomp/balance", {autoCall: true})

    const renderToast = useToast();
    const {user, logout} = useAuth();
    const navigate = useNavigate();

    // Sick leave
    const [showSickLeaveModal, setShowSickLeaveModal] = useState(false);
    const [editSickleaveRequest, setEditSickleaveRequest] = useState({});

    const handleSickLeaveClick = () => {
        setShowSickLeaveModal(true);
    };

    const confirmSickLeave = (sickLeaveRequest) => {
        // convert to unix timestamp
        sickLeaveRequest.startDate = new Date(sickLeaveRequest.startDate).getTime();
        sickLeaveRequest.endDate = new Date(sickLeaveRequest.endDate).getTime();

        // call edit if editSickleaveRequest is set
        if (editSickleaveRequest.id) {
            callSickleaveEdit({body: sickLeaveRequest}).then(data => {
                getSickleaves();
                renderToast('Krankmeldung erfolgreich bearbeitet!', "text-bg-primary");
            });
            setEditSickleaveRequest({});
            return;
        }

        callSickleaveAPI({body: sickLeaveRequest}).then(data => {
            getSickleaves();
            renderToast('Krankmeldung erfolgreich eingereicht!', "text-bg-primary");
        });
    };

    const deleteSickleave = () => {
        callSickleaveDelete({endpointAddition: `/${editSickleaveRequest.id}`}).then(data => {
            getSickleaves();
            setEditSickleaveRequest({});
            setShowSickLeaveModal(false);
            renderToast('Krankmeldung erfolgreich gelöscht!', "text-bg-primary");
        });
    }

    const sickleaveClick = (sickleave) => {
        setEditSickleaveRequest(sickleave);
        setShowSickLeaveModal(true);
    };

    // Holiday request
    const [showHolidayRequestModal, setShowHolidayRequestModal] = useState(false);
    const [editHolidayRequest, setEditHolidayRequest] = useState({});

    const handleHolidayRequestClick = () => {
        setShowHolidayRequestModal(true);
    };

    const confirmHolidayRequest = (holidayRequest) => {
        // convert to unix timestamp
        holidayRequest.startDate = new Date(holidayRequest.startDate).getTime();
        holidayRequest.endDate = new Date(holidayRequest.endDate).getTime();

        // call edit if editHolidayRequest is set
        if (editHolidayRequest.id) {
            callHolidayEdit({body: holidayRequest}).then(data => {
                getHolidays();
                renderToast('Urlaubsantrag erfolgreich bearbeitet!', "text-bg-primary");
            });
            setEditHolidayRequest({});
            return;
        }

        callHolidayAPI({body: holidayRequest}).then(data => {
            getHolidays();
            renderToast('Urlaubsantrag erfolgreich eingereicht!', "text-bg-primary");
        });
    };

    const deleteHolidayRequest = () => {
        callHolidayDelete({endpointAddition: `/${editHolidayRequest.id}`}).then(data => {
            getHolidays();
            setEditHolidayRequest({});
            setShowHolidayRequestModal(false);
            renderToast('Urlaubsantrag erfolgreich gelöscht!', "text-bg-primary");
        });
    }

    const holidayClick = (holidayRequest) => {
        setEditHolidayRequest(holidayRequest);
        setShowHolidayRequestModal(true);
    };

    // Time compensation request
    const [showTimeCompRequestModal, setShowTimeCompRequestModal] = useState(false);
    const [editTimeCompRequest, setEditTimeCompRequest] = useState({});

    const handleTimeCompRequestClick = () => {
        setShowTimeCompRequestModal(true);
    };

    const confirmTimeCompRequest = (timeCompRequest) => {
        // convert to unix timestamp
        timeCompRequest.startDate = new Date(timeCompRequest.startDate).getTime();
        timeCompRequest.endDate = new Date(timeCompRequest.endDate).getTime();

        // call edit if editTimeCompRequest is set
        if (editTimeCompRequest.id) {
            callTimeCompEdit({body: timeCompRequest}).then(data => {
                getTimeComps();
                getTimeCompBalance();
                renderToast('Zeitausgleich erfolgreich bearbeitet!', "text-bg-primary");
            }).catch(err => {
                renderToast(err.response.data, "text-bg-danger");
            });
            setEditTimeCompRequest({});
            return;
        }

        callTimeCompAPI({body: timeCompRequest}).then(data => {
            getTimeComps();
            getTimeCompBalance();
            renderToast('Zeitausgleich erfolgreich eingereicht!', "text-bg-primary");
        }).catch(err => {
            renderToast(err.response.data, "text-bg-danger");
        });
    };

    const deleteTimeCompRequest = () => {
        callTimeCompDelete({endpointAddition: `/${editTimeCompRequest.id}`}).then(data => {
            getTimeComps();
            getTimeCompBalance();
            setEditTimeCompRequest({});
            setShowTimeCompRequestModal(false);
            renderToast('Zeitausgleich erfolgreich gelöscht!', "text-bg-primary");
        });
    }

    const timeCompClick = (timeCompRequest) => {
        setEditTimeCompRequest(timeCompRequest);
        setShowTimeCompRequestModal(true);
    };

    return (
        <>
            <SickLeaveModal
                show={showSickLeaveModal}
                setShow={setShowSickLeaveModal}
                onConfirm={confirmSickLeave}
                onDelete={deleteSickleave}
                editSickleaveRequest={editSickleaveRequest}
                setEditSickleaveRequest={setEditSickleaveRequest}
            />
            <HolidayRequestModal
                show={showHolidayRequestModal}
                setShow={setShowHolidayRequestModal}
                onConfirm={confirmHolidayRequest}
                onDelete={deleteHolidayRequest}
                editHolidayRequest={editHolidayRequest}
                setEditHolidayRequest={setEditHolidayRequest}
            />
            <TimeCompRequestModal
                show={showTimeCompRequestModal}
                setShow={setShowTimeCompRequestModal}
                onConfirm={confirmTimeCompRequest}
                onDelete={deleteTimeCompRequest}
                editTimeCompRequest={editTimeCompRequest}
                setEditTimeCompRequest={setEditTimeCompRequest}
            />
            <div className={"h-100 overflow-y-auto overflow-x-hidden max-width-600"}>
                <div className="bg-light rounded-bottom-5 shadow-white">
                    <div className={"d-flex justify-content-center position-relative"}>
                        <div className={"py-3"}>
                            {user?.username}
                        </div>
                        <div className={"py-2"} role={"button"} style={{position: 'absolute', right: 0}}>
                            <Dropdown>
                                <Dropdown.Toggle className={"hide-caret text-dark"} variant="link" id="dropdown-basic">
                                    <i className="bi bi-three-dots-vertical"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={"text-end gap-3 mx-2"}>
                                    <Dropdown.Item
                                        onClick={() => navigate("/changepassword")}
                                    >
                                        Passwort ändern
                                    </Dropdown.Item>
                                    <Dropdown.Item className={"text-danger"} onClick={logout}>Ausloggen</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={"py-4"}>
                        <div className={"d-flex justify-content-center align-items-center"}>
                            <div
                                className={"bg-primary rounded-circle d-flex justify-content-center align-items-center shadow"}
                                style={{width: "6em", height: "6em"}}
                            >
                                <i className="bi bi-person text-light" style={{fontSize: "3.5em"}}></i>
                            </div>
                        </div>
                        <div className={"text-center py-3"}>
                            <h1 className={"fs-5 fw-bold mb-1"}>{user?.firstname} {user?.lastname}</h1>
                            <h2 className={"lead"} style={{fontSize: "0.9em"}}>{userTypeString(user)}</h2>
                        </div>
                    </div>
                </div>

                <div className={"my-5 leave-buttons"}>
                    <div
                        className={"bg-gradient-primary rounded-5 m-4 d-flex justify-content-between align-items-end"}
                        role={"button"}
                        onClick={handleHolidayRequestClick}
                        style={{height: 125}}
                    >
                        <Image
                            src={"/request_free.png"}
                            className={"rounded-circle shadow"}
                            style={{margin: 12}}
                            height={101}
                        />
                        <div
                            className={"fs-3 fw-bold text-light text-end m-3"}
                        >
                            Urlaub<br/>beantragen
                        </div>
                    </div>
                    <div
                        className={"bg-gradient-warning rounded-5 m-4 d-flex justify-content-between align-items-end"}
                        role={"button"}
                        onClick={handleSickLeaveClick}
                        style={{height: 125}}
                    >
                        <Image
                            src={"/sick_leave.png"}
                            className={"rounded-circle shadow"}
                            style={{margin: 12}}
                            height={101}
                        />
                        <div
                            className={"fs-3 fw-bold text-light text-end m-3"}
                        >
                            Krankmeldung
                        </div>
                    </div>
                    <div
                        className={"bg-gradient-success rounded-5 m-4 d-flex justify-content-between align-items-end"}
                        role={"button"}
                        onClick={handleTimeCompRequestClick}
                        style={{height: 125}}
                    >
                        <Image
                            src={"/time_comp.png"}
                            className={"rounded-circle shadow"}
                            style={{margin: 12}}
                            height={101}
                        />
                        <div
                            className={"fs-3 fw-bold text-light text-end h-100 d-flex justify-content-between flex-column p-3"}
                        >
                            <div>
                                <span className={"bg-success-subtle text-dark rounded p-1 px-2"}
                                      style={{fontSize: "0.75em"}}>
                                    {millisToDecimalHours(timeCompBalance?.newTotalOvertime)} h
                                </span>
                            </div>
                            <div>Zeitausgleich</div>
                        </div>
                    </div>
                </div>

                <div className={"d-flex flex-column gap-3 mx-3 mb-3"}>
                    {
                        holidays?.map((holidayRequest) => {
                            return (
                                <CompactHolidayCard
                                    key={holidayRequest.id}
                                    holidayRequest={holidayRequest}
                                    onClick={() => holidayClick(holidayRequest)}
                                />
                            )
                        })
                    }
                    {
                        sickleaves?.map((sickLeaveRequest) => {
                            return (
                                <CompactSickLeaveCard
                                    key={sickLeaveRequest.id}
                                    sickLeaveRequest={sickLeaveRequest}
                                    onClick={() => sickleaveClick(sickLeaveRequest)}
                                />
                            )
                        })
                    }
                    {
                        timeComps?.map((timeCompRequest) => {
                            return (
                                <CompactTimeCompCard
                                    key={timeCompRequest.id}
                                    timeCompRequest={timeCompRequest}
                                    onClick={() => timeCompClick(timeCompRequest)}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default Personal;